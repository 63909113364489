import ChartDots from "shared/assets/icons/chart-dots";
import Eye from "shared/assets/icons/eye";
import Notebook from "shared/assets/icons/notebook";
import Picture from "shared/assets/icons/picture";
import VolumeMax from "shared/assets/icons/volume-max";
import Micro from "shared/assets/icons/micro";
import Life02 from "shared/assets/icons/life-buoy-02";
import { RoutePath } from "shared/config/routeConfig/routeConfig";
import Stack from "shared/assets/icons/stack";
import IcYoutube from "shared/assets/icons/IcYoutube";
import File02 from "shared/assets/icons/file-02";
import Ad2 from "shared/assets/icons/ad-2";
import Vector from "shared/assets/icons/vector";

export const MENU_ITEMS = [
  {
    title: "Главная",
    icon: <ChartDots />,
    path: RoutePath.MAIN,
  },
  // {
  //   id: AppRoutes.DOCUMENTS,
  //   title: "Документы",
  //   icon: <Archive />,
  //   path: RoutePath.DOCUMENTS,
  // },
  {
    title: "Редактор текста",
    icon: <Notebook />,
    path: RoutePath.TEXT_EDITOR,
  },
  {
    title: "Чат-бот",
    icon: <Eye />,
    path: RoutePath.CHAT_BOT,
  },

  {
    title: "Генерация картинок",
    icon: <Picture />,
    path: RoutePath.AI_IMAGES,
  },
  {
    title: "Замена фона",
    icon: <Stack />,
    path: RoutePath.BACKGROUND_REPLACEMENT,
  },
  // {
  //   title: "Анализатор файлов",
  //   icon: <File03 />,
  //   path: RoutePath.FILE_ANALYZER,
  //   disabled: true,
  // },
  // {
  //   title: "Генерация кода",
  //   icon: <Terminal />,
  //   path: RoutePath.CODE_GENERATION,
  //   disabled: true,
  // },
  // {
  //   title: "Анимация фото",
  //   icon: <Video />,
  //   path: RoutePath.PHOTO_ANIMATION,
  //   disabled: true,
  // },
  {
    title: "Расшифровка аудио",
    icon: <Micro color="#98A2B3" />,
    path: RoutePath.AUDIO_TRANSCRIPT,
  },
  {
    title: "Озвучка текста",
    icon: <VolumeMax />,
    path: RoutePath.VOICE_OVER,
  },

  {
    title: "Служба поддержки",
    icon: <Life02 />,
    path: RoutePath.SUPPORT,
  },
  {
    title: "Генерация видео",
    icon: <IcYoutube />,
    path: RoutePath.AI_VIDEOS,
  },
  {
    title: "AI шаблоны",
    icon: <File02 />,
    path: RoutePath.AI_TEMPLATES,
    disabled: true,
  },
  {
    title: "Конструктор статей",
    icon: <Ad2 />,
    path: RoutePath.ARTICLES_CONSTRUCTOR,
    disabled: true,
  },
  {
    title: "Генерация логотипа",
    icon: <Vector />,
    path: RoutePath.LOGO_GENERATION,
    disabled: true,
  },
];
