import React, { memo, useMemo, useRef, useState } from "react";
import s from "./Input.module.css";
import EyeOff from "shared/assets/icons/eye-off";
import Eye from "shared/assets/icons/eye";
import Button from "../Button/Button";
import AlertCircle from "shared/assets/icons/alert-circle";
import { BtnColor, BtnSize } from "../Button/types";
import { INPUT_COLORS, INPUT_SIZES, INPUT_TYPES, TInput } from "./types";
import { classNames } from "shared/lib/classNames/classNames";
import { addPhoneMask } from "shared/lib/addPhoneMask/addPhoneMask";

const Input: React.FC<TInput> = memo(
  ({
    id,
    placeholder,
    value,
    onChange,
    type = INPUT_TYPES.TEXT,
    invalidText,
    invalid,
    onBlur,
    label,
    autocomplete = "on",
    size = INPUT_SIZES.MEDIUM,
    description,
    squared = false,
    disabled,
    className,
    color = INPUT_COLORS.WHITE,
  }) => {
    const [isPassShown, setIsPassShown] = useState<boolean>(false);
    const [isInputActive, setIsInputActive] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const inputType = useMemo(
      () =>
        type === INPUT_TYPES.PASSWORD
          ? isPassShown
            ? "text"
            : "password"
          : type,
      [isPassShown, type]
    );
    const handleInputFocus = () => setIsInputActive(true);

    const handleInputBlur = () => {
      onBlur && onBlur();
      setIsInputActive(false);
    };

    const handleContainerClick = (event: any) => {
      if (
        event.target.tagName !== "BUTTON" &&
        event.target.tagName !== "svg" &&
        event.target.tagName !== "path"
      ) {
        inputRef.current?.focus();
        setIsInputActive(true);
      }
    };
    const mods = {
      [s.invalid]: invalidText || invalid,
      [s.active]: isInputActive,
      [s[size]]: size,
      [s.squared]: squared,
      [s.disabled]: disabled,
      [s[color]]: color,
    };

    const onPhoneChange = (
      value: any | React.ChangeEvent<HTMLInputElement>
    ) => {
      const res = addPhoneMask(value);
      onChange(res);
    };
    const onValueChange = (e: any) => {
      type === INPUT_TYPES.TEL
        ? onPhoneChange(e.target)
        : onChange(e.target?.value);
    };
    return (
      <div className={s.container}>
        {label && (
          <label htmlFor={id} className={s.label}>
            {label}
          </label>
        )}
        <div
          className={classNames(s.inputContainer, mods, [className!])}
          onClick={handleContainerClick}
        >
          <input
            ref={inputRef}
            id={id}
            type={inputType}
            value={value}
            placeholder={placeholder}
            onFocus={handleInputFocus}
            onChange={onValueChange}
            onBlur={handleInputBlur}
            className={classNames(s.input, {
              [s[size]]: size,
              [s.squared]: squared,
            })}
            autoComplete={autocomplete}
            disabled={disabled}
          />
          {type === INPUT_TYPES.PASSWORD && (
            <Button
              color={BtnColor.CLEAR}
              size={BtnSize.XXSMALL}
              className={s.eyeButton}
              onClick={(e: any) => {
                e.preventDefault();
                setIsPassShown((prev) => !prev);
              }}
              disabled={disabled}
            >
              {isPassShown ? <EyeOff /> : <Eye />}
            </Button>
          )}
        </div>

        {description && !invalidText ? (
          <p className={classNames(s.description, { [s[size]]: size })}>
            {description}
          </p>
        ) : (
          invalidText && (
            <div className="flex items-center gap-2">
              {/* <AlertCircle /> */}
              <span className={classNames(s.invalidText, { [s[size]]: size })}>
                {invalidText}
              </span>
            </div>
          )
        )}
      </div>
    );
  }
);

export default Input;
