import useHttp from "shared/api/services/api";
import { API_PREFIX } from "shared/api/constants/api";

export default class subPlansApi {
  static http = useHttp(API_PREFIX.SUB_PLANS);

  static async getSubPlansList() {
    const { data } = await subPlansApi.http.get("");
    return data;
  }

  static async getSubPlan(id: string) {
    const { data } = await subPlansApi.http.get(`/${id}`);
    return data;
  }
  // todo ts
  static async createSubPlan(plan: any) {
    const { data } = await subPlansApi.http.post(`/admin/create`, plan);
    return data;
  }

  static async updateSubPlan(subId: string, newPlanDate: any) {
    const { data } = await subPlansApi.http.patch(
      `/admin/update/${subId}`,
      newPlanDate
    );
    return data;
  }

  static async deleteSubPlan(subId: string) {
    const { data } = await subPlansApi.http.delete(`//admin/delete/${subId}`);
    return data;
  }
}
