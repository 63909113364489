// function debounce<T extends (...args: any[]) => any>(
//   callee: T,
//   timeoutMs: number
// ): (...args: Parameters<T>) => void {
//   let lastCallTimer: NodeJS.Timeout | undefined;
//   // Хранит таймер
//   let lastCall: number = 0; // Время последнего вызова
//   return function perform(...args: Parameters<T>): void {
//     const context = this;
//     // Храним контекст вызова
//     lastCall = Date.now(); // Обновляем время последнего вызова
//     // Если есть предыдущий таймер, очищаем его
//     if (lastCallTimer) {
//       clearTimeout(lastCallTimer);
//     } // Устанавливаем новый таймер
//     lastCallTimer = setTimeout(() => callee.apply(context, args), timeoutMs);
//   };
// }

type DebounceFunction = (...args: any[]) => void;
export function debounce<Func extends DebounceFunction>(
  func: Func,
  delay: number
): Func {
  let timeoutId: NodeJS.Timeout | null = null;
  return function (...args: Parameters<Func>): void {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  } as Func;
}
