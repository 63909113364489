import { COMMON_ERROR_TEXT, TCommonError } from "shared/api/constants/errors";

export const createErrorNotification = (
  err: any,
  setNotifications: (prev: any) => void
) => {
  const errorKey = (
    Array.isArray(err?.response?.data.detail)
      ? err.response?.data.detail[0].msg || err.response?.data.detail[0]
      : err.response?.data.detail || err.message
  ) as TCommonError;
  const id = Date.now();
  const errorMessage =
    errorKey in COMMON_ERROR_TEXT
      ? COMMON_ERROR_TEXT[errorKey]
      : COMMON_ERROR_TEXT.UNKNOWN_ERROR;
  setNotifications((prev: any) => [
    ...prev,
    { id, text: errorMessage, type: "error" },
  ]);
};
