import { createAsyncThunk } from "@reduxjs/toolkit";
import { TChat } from "../../slice/types";
import { chatBotActions } from "../../slice";
import { chatBotApi } from "../api/chatBotApi";
import { chatBotThunks } from "..";

interface TCurrentChat {
  id: TChat["id"];
}
export const getCurrentChat = createAsyncThunk<any, TCurrentChat>(
  "chatBot/getCurrentChat",
  async ({ id }, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    // dispatch(chatBotActions.isLoading(true));

    try {
      dispatch(chatBotActions.isError(null));
      const res = await chatBotApi.getChat(id);
      // dispatch(chatBotThunks.getChatMessages({ id, offset: 0 }));

      return {
        currentChat: res,
      };
    } catch (e) {
      console.error(e);
      dispatch(chatBotActions.isError(e));

      return rejectWithValue(e);
    } finally {
      // dispatch(chatBotActions.isLoading(false));
    }
  }
);
