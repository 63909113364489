import React from "react";
import IcGradientEye from "shared/assets/icons/IcGradientEye";
import IcGradientFile03 from "shared/assets/icons/IcGradientFile03";
import IcGradientStack from "shared/assets/icons/IcGradientStack";
import XClose from "shared/assets/icons/x-close";
import Button from "shared/ui/Button/Button";
import { BtnColor, BtnSize } from "shared/ui/Button/types";
import CardBacking from "shared/ui/CardBacking/CardBacking";
import { ModalOverlay } from "shared/ui/ModalOverlay/ModalOverlay";
import s from "./WelcomeModal.module.css";
import IcGradientStars from "shared/assets/icons/IcGradientStars";

const FEATURES = [
  {
    title: "Редактор текста",
    icon: <IcGradientFile03 />,
    description: `В стиле Notion для ещё более удобной работы`,
  },
  {
    title: "Чат-бот",
    icon: <IcGradientEye />,
    description: `Расширенный функционал для решения ваших задач`,
  },
  {
    title: "Замена фона",
    icon: <IcGradientStack />,
    description: `Чтобы ваши изображения выглядели профессионально`,
  },
  {
    title: "Новые нейросети",
    icon: <IcGradientStars />,
    description: `Чтобы вы всегда оставались на шаг впереди`,
  },
];
const WelcomeModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <ModalOverlay open={open} onClose={onClose}>
      <CardBacking className={s.cardContainer}>
        <div>
          <div className="flex items-start justify-between gap-8">
            <h3>
              Поздравляем!
              <br />У вас открыт доступ к новой версии MonoAI
            </h3>
            <Button
              color={BtnColor.CLEAR}
              size={BtnSize.XXSMALL}
              onClick={onClose}
            >
              <XClose />
            </Button>
          </div>
          <p className="text-gray-400 pt-2">
            Теперь вы можете оценить новые возможности MonoAI
          </p>
        </div>
        <div className={s.featureContainer}>
          {FEATURES.map((item, index) => (
            <div className={s.featureCard} key={index}>
              <div className={s.icon}>{item.icon}</div>
              <div>
                <h6>{item.title}</h6>
                <p className="text-gray-400 f-sm">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-3">
          <div className={s.btnContainer}>
            <Button
              className={s.button}
              color={BtnColor.WHITE}
              onClick={onClose}
              isFull
              href="https://old.monoai.ru/login"
              target="_blank"
            >
              Вернуться на старую версию
            </Button>
            <Button
              className={s.button}
              color={BtnColor.BLACK}
              onClick={onClose}
              isFull
            >
              Остаться
            </Button>
          </div>
          <p className="text-gray-400 text-center text-sm">
            Версия дорабатывается, поэтому возможны ошибки и баги.
            <br />
            Мы благодарны за ваше понимание и обратную связь!
          </p>
        </div>
      </CardBacking>
    </ModalOverlay>
  );
};

export default WelcomeModal;
