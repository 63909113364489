import { memo, useEffect, useState } from "react";
import AlertCircle from "../../../shared/assets/icons/alert-circle";
import CheckCircle from "../../../shared/assets/icons/check-circle";
import ErrorCircle from "../../../shared/assets/icons/error-circle";
import Info from "../../../shared/assets/icons/info";
import s from "./Notification.module.css";
import { classNames } from "shared/lib/classNames/classNames";

export enum NotificationType {
  Alert = "alert",
  Success = "success",
  Error = "error",
  Info = "info",
}
export type TNotification = {
  type?: NotificationType;
  text: string;
  onHide?: () => void;
};

const ICONS = {
  info: <Info />,
  success: <CheckCircle />,
  alert: <AlertCircle />,
  error: <ErrorCircle />,
};

const duration = 5000;
const timeoutDelay = 1000;
const animationDuration = 500;

const Notification = memo(
  ({ type = NotificationType.Error, text, onHide }: TNotification) => {
    const [width, setWidth] = useState(0);
    const [visible, setVisible] = useState(false);

    const [shouldRender, setShouldRender] = useState(false); // Для управления отрисовкой компонента
    useEffect(() => {
      if (text) {
        setShouldRender(true); // Рендерим элемент
        setVisible(true);

        const timer = setTimeout(() => {
          setVisible(false);
        }, duration + timeoutDelay);
        const hideTimer = setTimeout(
          () => {
            onHide?.();
            setShouldRender(false); // Убираем элемент из рендера
            setWidth(0);
          },
          duration + timeoutDelay + animationDuration
        );
        return () => {
          clearTimeout(timer);
          clearTimeout(hideTimer);
        };
      }
    }, [text]);

    useEffect(() => {
      if (text) {
        // Устанавливаем таймер на заданную длительность
        const interval = duration / 50; // делим длительность на 50 шагов
        let currentWidth = 0;
        const timer = setInterval(() => {
          if (currentWidth < 100) {
            currentWidth += 2;
            setWidth(currentWidth);
          } else {
            clearInterval(timer);
          }
        }, interval);

        return () => clearInterval(timer);
      }
    }, [text]);

    if (!shouldRender) return null; // Не рендерим компонент, если не должно быть видимо

    return (
      <div className={classNames(s.container, { [s.shown]: visible })}>
        <div className={classNames(s.notification, {}, [s[type]])}>
          {ICONS[type]}
          <p className={"primary-text-xs"}>{text}</p>
        </div>
        <div className={s.loaderContainer}>
          <div
            className={`${s.loader} ${s[type]}`}
            style={{ width: `${width}%` }}
          />
        </div>
      </div>
    );
  }
);

export default Notification;
