export const IcAirplane = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10 14L21 3"
        stroke="#F2F4F7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0001 3L14.5001 21C14.4105 21.1955 14.2151 21.3209 14.0001 21.3209C13.785 21.3209 13.5897 21.1955 13.5001 21L10.0001 14L3.00007 10.5C2.80455 10.4104 2.6792 10.2151 2.6792 10C2.6792 9.78493 2.80455 9.5896 3.00007 9.5L21.0001 3"
        stroke="#F2F4F7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
