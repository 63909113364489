import { useContext } from "react";
import { LOCAL_STORAGE_THEME_KEY, ThemeContext, TTheme } from "./ThemeContext";

interface TUseThemeResult {
  theme: TTheme;
  toggleTheme: () => void;
}
export const useTheme = (): TUseThemeResult => {
  const { theme, setTheme } = useContext(ThemeContext);

  const toggleTheme = () => {
    const newTheme = theme === TTheme.LIGHT ? TTheme.DARK : TTheme.LIGHT;
    document.body.className = newTheme;
    localStorage.setItem(LOCAL_STORAGE_THEME_KEY, newTheme);
    setTheme(newTheme);
  };
  return { theme, toggleTheme };
};
