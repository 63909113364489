import { messages } from "./../../../../../../../node_modules/workbox-strategies/src/utils/messages";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatBotApi } from "../api/chatBotApi";
import { TChat } from "../../slice/types";
import { chatBotActions } from "../../slice";

export const getChatMessages = createAsyncThunk(
  "chatBot/getChatMessages",
  async (
    { id, offset = 0 }: { id: TChat["id"]; offset: number; limit?: number },
    thunkAPI
  ) => {
    const { rejectWithValue, dispatch, getState } = thunkAPI;
    // @ts-ignore
    const { chatBot } = getState();
    // console.log(chatBot.currentChat);

    // dispatch(chatBotActions.isLoading(true));
    if (
      id === chatBot?.currentChat?.id &&
      // chatBot?.currentChat?.messages_story &&
      chatBot?.currentChat?.messages_story?.length > 0 &&
      offset === 0
    ) {
      return {
        currentChatMessages: [],
      };
    }
    try {
      dispatch(chatBotActions.isError(null));

      const res = await chatBotApi.getMessagesStory(id, offset);
      return {
        currentChatMessages: res.messages_story,
      };
    } catch (e) {
      console.error(e);
      dispatch(chatBotActions.isError(e));
      return rejectWithValue(e);
    } finally {
      // dispatch(chatBotActions.isLoading(false));
    }
  }
);
