import { createAsyncThunk } from "@reduxjs/toolkit";
import { ServerMessage } from "../../types/supportDataTypes";
import SupportApi from "../../api/supportApi";

export const createNewMessageByAdmin = createAsyncThunk<
  { message: ServerMessage },
  {
    chatId: string;
    text: string;
    attachments: File[];
  }
>("support/createNewMessageByAdmin", async (arg, thunkApi) => {
  const { rejectWithValue } = thunkApi;

  try {
    const res = await SupportApi.createNewMessageByAdmin({
      text: arg.text,
      chatId: arg.chatId,
      attachments: arg.attachments,
    });
    return { message: res };
  } catch (e) {
    return rejectWithValue(e);
  }
});
