import { createAsyncThunk } from "@reduxjs/toolkit";
import { userApi, TUpdatedUserData } from "../api/userApi";
import { userActions } from "../../slice";

export const updateUserData = createAsyncThunk(
  "user/updateUserData",
  async ({ updatedData }: { updatedData: TUpdatedUserData }, { dispatch }) => {
    // dispatch(userActions.isLoading(true));
    dispatch(userActions.isError(null));
    try {
      const user = await userApi.updateUser(updatedData);
      return { user };
    } catch (e: any) {
      dispatch(userActions.isError(e));
      throw new Error(e?.response?.data?.detail?.[0].msg);
    } finally {
      // dispatch(userActions.isLoading(false));
    }
  }
);
