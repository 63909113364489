import React from "react";
import styles from "./MessagesWrapper.module.scss";
import { ServerMessage } from "pages/content/Support/model/types/supportDataTypes";

interface MessagesWrapperProps {
  message: ServerMessage;
}

const MessagesWrapper: React.FC<MessagesWrapperProps> = ({ message }) => {
  const isAdmin = message.from_admin;
  const senderName = isAdmin ? "Администратор" : "Вы";
  const messageTime = new Date(message.created_at).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div className={styles.messageContainer}>
      {!isAdmin && (
        <>
          <div className={styles.senderName}>{senderName}</div>
          <div className={styles.userMessage}>
            <p>{message.text}</p>
          </div>
          <span className={styles.messageTimeUser}>{messageTime}</span>
        </>
      )}
      {isAdmin && (
        <>
          <div className={styles.receiverName}>{senderName}</div>
          <div className={styles.assistantMessage}>
            <p>{message.text}</p>
          </div>
          <span className={styles.messageTime}>{messageTime}</span>
        </>
      )}
    </div>
  );
};

export default MessagesWrapper;
