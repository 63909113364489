import { FC, useEffect, useMemo, useState } from "react";
import {
  LOCAL_STORAGE_THEME_KEY,
  ThemeContext,
  TTheme,
} from "../lib/ThemeContext";

export const defaultTheme =
  (localStorage.getItem(LOCAL_STORAGE_THEME_KEY) as TTheme) || TTheme.LIGHT;

export interface TThemeProviderProps {
  initialTheme?: TTheme;
  children: React.ReactNode;
}
export const ThemeProvider: FC<TThemeProviderProps> = ({
  children,
  initialTheme = defaultTheme,
}) => {
  const [theme, setTheme] = useState<TTheme>(initialTheme);

  const themeValue = useMemo(() => ({ theme, setTheme }), [theme]);

  useEffect(() => {
    document.body.className = theme;
  }, []);

  return (
    <ThemeContext.Provider value={themeValue}>{children}</ThemeContext.Provider>
  );
};
