import { classNames } from "shared/lib/classNames/classNames";
import cn from "./ToBack.module.scss";
import { IcLeftArrow } from "../../assets/icons/IcLeftArrow";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

type ToBackProps = {
  className?: string;
  title: string;
};

export const ToBack: FC<ToBackProps> = ({ className, title }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div
      onClick={handleBack}
      className={classNames(cn.container, {}, [className ? className : ""])}
    >
      <IcLeftArrow />
      <span className={classNames(cn.title)}>{title}</span>
    </div>
  );
};
