const IcPremiumToken = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1C14.9705 1 19 5.02944 19 10C19 14.9705 14.9705 19 10 19C5.02944 19 1 14.9705 1 10C1 5.02944 5.02944 1 10 1ZM10 5.125C12.0508 5.125 13.2402 5.87849 13.8694 8.47384C14.4986 11.0692 15.25 14.125 15.25 14.125H13.0352C13.0352 14.125 11.8457 9.22735 11.6546 8.47384C11.6496 8.45415 11.6445 8.43372 11.639 8.41259C11.4401 7.62535 10.9986 5.87849 10 5.87849C8.9746 5.87849 8.52347 7.72035 8.34542 8.47384C8.16745 9.22735 6.96484 14.125 6.96484 14.125H4.75C4.75 14.125 5.48829 11.0692 6.13058 8.47384C6.77289 5.87849 7.94921 5.125 10 5.125Z"
      fill="#2871FF"
    />
  </svg>
);

export default IcPremiumToken;
