import { createAsyncThunk } from "@reduxjs/toolkit";
import { textDocumentsActions } from "../../slice";

import textDocumentsApi from "../api/documentsApi";
import { textDocumentsThunks } from "..";
interface TextDocument {
  id: string;
}
export const getCurrentTextDocument = createAsyncThunk<any, TextDocument>(
  "textDocuments/getCurrentTextDocument",
  async ({ id }, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    // dispatch(textDocumentsActions.isLoading(true));

    try {
      dispatch(textDocumentsActions.isError(null));
      const res = await textDocumentsApi.getTextDocument(id);
      //получить аватарку
      // dispatch(textDocumentsThunks.getAvatarDocKnBaseAT({ doc_id: arg.id }));

      //получить body
      dispatch(
        textDocumentsThunks.getDocumentBody({
          id,
          version: res.mongo_doc_id,
        })
      );

      return {
        currentDoc: res,
        // relations: res.data.relations,
      };
    } catch (e) {
      dispatch(textDocumentsActions.isError(e));
      return rejectWithValue(e);
    } finally {
      // dispatch(textDocumentsActions.isLoading(false));
    }
  }
);
