import { createAsyncThunk } from "@reduxjs/toolkit";
// import { getAllDocuments } from "src/pages/api/requests/knowledgeBase";
import { textDocumentsActions } from "../../slice";
// import { getWorkerMe } from "../../../../pages/api/requests/worker";
import { textDocumentsThunks } from "../index";
import documentsApi from "../api/documentsApi";

/** Получение всех документов */
export const getAllTextDocuments = createAsyncThunk(
  "textDocuments/getAllTextDocuments",
  async (arg, { rejectWithValue, dispatch }) => {
    dispatch(textDocumentsActions.isLoading(true));
    try {
      dispatch(textDocumentsActions.isError(null));
      dispatch(textDocumentsThunks.getGptModels());
      const res = await documentsApi.getAllTextDocuments();
      // console.log(res);
      // const worker_id = await getWorkerMe({ organization_id: arg.org_id });
      // const {data} = await getAllDocuments({
      //   org_id: arg.org_id,
      //   worker_id: worker_id.id,
      // });
      //получить все аватарки
      // dispatch(
      //   textDocumentsActions.getAllAvatarDocKnBaseAT({ documents: res.data })
      // );
      return { allTextDocuments: res };
    } catch (e) {
      dispatch(textDocumentsActions.isError(e));
      rejectWithValue(e);
    } finally {
      dispatch(textDocumentsActions.isLoading(false));
    }
  }
);
