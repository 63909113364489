import Logo from "../../../../shared/assets/icons/logo";
import s from "./AuthHeader.module.css";
const AuthHeader = () => {
  return (
    <header className={s.header}>
      <Logo />
    </header>
  );
};

export default AuthHeader;
