import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Sidebar } from "widgets/Sidebar";
import s from "./PageLayout.module.css";
import { Header } from "widgets/Header";
import Footer from "../../../../widgets/Footer/Footer";

export const PageLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex">
      <Sidebar sidebarOpened={sidebarOpen} setSidebarOpened={setSidebarOpen} />
      <div className={s.contentWrapper}>
        <Header setSidebarOpen={setSidebarOpen} />
        <div className={s.content}>
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
};
