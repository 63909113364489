import { addChatToFolder } from "./thunks/addChatToFolder";
import { createNewChat } from "./thunks/createNewChat";
import { createNewFolder } from "./thunks/createNewFolder";
import { deleteChat } from "./thunks/deleteChat";
import { deleteChatFromFolder } from "./thunks/deleteChatFromFolder";
import { deleteFolderWithChats } from "./thunks/deleteFolderWithChats";
import { getAllChats } from "./thunks/getAllChats";
import { getChatMessages } from "./thunks/getChatMessages";
import { getCurrentChat } from "./thunks/getCurrentChat";
import { getGptModels } from "./thunks/getGptModels";
import { updateChat } from "./thunks/updateChat";
import { updateFolder } from "./thunks/updateFolder";

export const chatBotThunks = {
  getAllChats,
  getCurrentChat,
  createNewChat,
  deleteChat,
  deleteFolderWithChats,
  getChatMessages,
  updateChat,
  createNewFolder,
  addChatToFolder,
  deleteChatFromFolder,
  updateFolder,
  getGptModels,
};
