import { createAsyncThunk } from "@reduxjs/toolkit";
import { userApi } from "../api/userApi";
import { userActions } from "../../slice";

export const updateUserAvatar = createAsyncThunk(
  "user/updateUserAvatar",
  async ({ newAvatar }: { newAvatar: File }, { dispatch }) => {
    dispatch(userActions.isError(null));
    try {
      const updatedAvatar = await userApi.updateAvatar(newAvatar);
      return { updatedAvatar };
    } catch (e) {
      dispatch(userActions.isError(e));
    } finally {
    }
  }
);
