import { FC } from "react";
import s from "./ActionIcon.module.css";
import { ActionIconSize, TActionIcon } from "./types";
import { classNames } from "../../lib/classNames/classNames";

const ActionIcon: FC<TActionIcon> = ({
  children,
  color,
  className = "",
  onClick,
  disabled,
  size = ActionIconSize.DEFAULT,
}) => {
  return (
    <button
      disabled={disabled}
      className={classNames(s.container, {}, [className, s[size], s[color]])}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default ActionIcon;
