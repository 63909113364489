import { addDocumentToFolder } from "./thunks/addDocumentToFolder";
import { createNewDocument } from "./thunks/createNewDoc";
import { createNewFolder } from "./thunks/createNewFolder";
import { createNewVersion } from "./thunks/createNewVersion";
import { deleteDocFromFolder } from "./thunks/deleteDocFromFolder";
import { deleteDocument } from "./thunks/deleteDocument";
import { deleteFolder } from "./thunks/deleteFolder";
import { duplicateDocument } from "./thunks/duplicateDocument";
import { getAllTextDocuments } from "./thunks/getAllTextDocuments";

import { getCurrentTextDocument } from "./thunks/getCurrentTextDocument";
import { getDocumentBody } from "./thunks/getDocumentBody";
import { getGptModels } from "./thunks/getGptModels";
import { updateDocument } from "./thunks/updateDocument";
import { updateFolder } from "./thunks/updateFolder";

export const textDocumentsThunks = {
  getAllTextDocuments,
  getCurrentTextDocument, //получить актуальный объект документа
  createNewDocument,
  deleteDocument,
  deleteFolder,
  updateDocument,
  getDocumentBody,
  duplicateDocument,
  createNewFolder,
  addDocumentToFolder,
  deleteDocFromFolder,
  updateFolder,
  createNewVersion,
  getGptModels,
};
