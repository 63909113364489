import { AuthWrapper } from "layouts/AuthWrapper";
import React, { useEffect } from "react";
import { RoutePath } from "shared/config/routeConfig/routeConfig";
import Button from "shared/ui/Button/Button";
import { BtnColor } from "shared/ui/Button/types";

const PasswordSuccessChanged = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = RoutePath.LOGIN;
    }, 3000);
  }, []);
  return (
    <AuthWrapper
      title="Пароль успешно изменён"
      description={
        <p>
          Сейчас перенаправим на главную страницу.
          <br />
          Если этого не произойдёт автоматически, нажми кнопку ниже.
        </p>
      }
      supportLink
    >
      <Button color={BtnColor.WHITE} isFull to={RoutePath.LOGIN}>
        Войти
      </Button>
    </AuthWrapper>
  );
};

export default PasswordSuccessChanged;
