const VolumeMax = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.7479 5.00017C21.1652 6.97041 22 9.3878 22 12.0002C22 14.6125 21.1652 17.0299 19.7479 19.0002M15.7453 8.00017C16.5362 9.134 17 10.5129 17 12.0002C17 13.4874 16.5362 14.8663 15.7453 16.0002M9.63432 4.36586L6.46863 7.53154C6.29568 7.70449 6.2092 7.79097 6.10828 7.85281C6.01881 7.90764 5.92127 7.94804 5.81923 7.97254C5.70414 8.00017 5.58185 8.00017 5.33726 8.00017H3.6C3.03995 8.00017 2.75992 8.00017 2.54601 8.10916C2.35785 8.20504 2.20487 8.35802 2.10899 8.54618C2 8.76009 2 9.04012 2 9.60017V14.4002C2 14.9602 2 15.2402 2.10899 15.4542C2.20487 15.6423 2.35785 15.7953 2.54601 15.8912C2.75992 16.0002 3.03995 16.0002 3.6 16.0002H5.33726C5.58185 16.0002 5.70414 16.0002 5.81923 16.0278C5.92127 16.0523 6.01881 16.0927 6.10828 16.1475C6.2092 16.2094 6.29568 16.2958 6.46863 16.4688L9.63431 19.6345C10.0627 20.0629 10.2769 20.2771 10.4608 20.2915C10.6203 20.3041 10.7763 20.2395 10.8802 20.1178C11 19.9775 11 19.6746 11 19.0688V4.93154C11 4.32573 11 4.02282 10.8802 3.88255C10.7763 3.76085 10.6203 3.69626 10.4608 3.70882C10.2769 3.72329 10.0627 3.93748 9.63432 4.36586Z"
      stroke="#101018"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default VolumeMax;
