import { createAsyncThunk } from "@reduxjs/toolkit";
import documentsApi from "../api/documentsApi";
import foldersApi from "../api/folderApi";
import { textDocumentsActions } from "../../slice";

export const deleteDocFromFolder = createAsyncThunk(
  "textDocuments/deleteDocFromFolder",
  async (arg: { entity_id: string; folder_id: string }, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      dispatch(textDocumentsActions.isError(null));

      // const res = await
      foldersApi.deleteDocumentFromFolder({
        doc_id: arg.entity_id,
        folder_id: arg.folder_id,
      });

      return { docId: arg.entity_id, folder_id: arg.folder_id };
    } catch (e) {
      dispatch(textDocumentsActions.isError(e));
      return thunkAPI.rejectWithValue(e);
    }
  }
);
