import { createAsyncThunk } from "@reduxjs/toolkit";
import SupportApi from "../../api/supportApi";
import { ServerMessage } from "../../types/supportDataTypes";

export const getChatsMessagesUser = createAsyncThunk<
  { userChat: ServerMessage[] },
  { chatId: string; offset: number; limit: number }
>("support/getChatsMessagesUser", async (arg, thunkApi) => {
  const { dispatch, rejectWithValue } = thunkApi;

  try {
    const res = await SupportApi.getChatsMessagesUser({
      chatId: arg.chatId,
      offset: arg.offset,
      limit: arg.limit,
    });

    return { userChat: res };
  } catch (e) {
    return rejectWithValue(e);
  }
});
