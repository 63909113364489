import { createAsyncThunk } from "@reduxjs/toolkit";
import foldersApi from "../api/foldersApi";
import { chatBotActions } from "../../slice";

export const addChatToFolder = createAsyncThunk(
  "chatBot/addChatToFolder",
  async (arg: { entity_id: string; folder_id: string }, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      dispatch(chatBotActions.isError(null));
      // await
      foldersApi.addChatToFolder({
        chat_id: arg.entity_id,
        folder_id: arg.folder_id,
      });

      return { folder_id: arg.folder_id, chatId: arg.entity_id };
    } catch (e: any) {
      dispatch(chatBotActions.isError(e));
      return rejectWithValue(e.response.data);
    }
  }
);
