import { createAsyncThunk } from "@reduxjs/toolkit";

import { textDocumentsActions } from "../../slice";
import documentsApi from "../api/documentsApi";

export const getDocumentBody = createAsyncThunk(
  "textDocuments/getDocumentBody",
  async ({ id, version }: { id: string; version: string }, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    let body = null;
    // dispatch(textDocumentsActions.isLoading(true));
    try {
      dispatch(textDocumentsActions.isError(null));
      const res = await documentsApi.getDocumentBody(id, version);
      body = res;
      if (body && body.template.length > 0) {
        for (let i = 0; i < body.template.length; i++) {
          //todo нужно фиксить , работает не оптимизировано
          const block = body.template[i];
          const cleanedPath = block.id.split("/imageID:")[0];
          if (block.id.includes("imageID")) {
            const resImage = await documentsApi.getFile(id, cleanedPath);

            if (resImage && resImage instanceof Blob) {
              const fileUrl = URL.createObjectURL(resImage);
              block.props.url = fileUrl;
            } else {
              throw new Error("Не удалось получить файл");
            }
          }
        }
      }

      return { body };
    } catch (e: any) {
      dispatch(
        textDocumentsActions.isError(e.message || "Ошибка при загрузке данных")
      );
    } finally {
      // dispatch(textDocumentsActions.isLoading(false));
    }
  }
);
