import { createAsyncThunk } from "@reduxjs/toolkit";

import SupportApi from "../../api/supportApi";

export const deleteRequestAT = createAsyncThunk<
  { id: string },
  {
    id: string;
  }
>("support/getSendRequestInDelete", async (arg, thunkApi) => {
  const { rejectWithValue } = thunkApi;

  try {
    await SupportApi.getSendRequestInDelete({ chatId: arg.id });
    return { id: arg.id };
  } catch (e) {
    return rejectWithValue(e);
  }
});
