import { createAsyncThunk } from "@reduxjs/toolkit";
import { TChat } from "../../slice/types";
import { chatBotActions } from "../../slice";
import { chatBotApi } from "../api/chatBotApi";

export const deleteChat = createAsyncThunk(
  "chatBot/deleteChat",
  async (
    {
      entity_id: chat_id,
      folder_id,
    }: { entity_id: TChat["id"]; folder_id?: string },
    thunkAPI
  ) => {
    const { rejectWithValue, getState, dispatch } = thunkAPI;

    try {
      dispatch(chatBotActions.isError(null));
      await chatBotApi.deleteChat(chat_id);

      return { folder_id, chat_id };
    } catch (e) {
      console.log(e);
      dispatch(chatBotActions.isError(e));
      rejectWithValue(e);
    }
  }
);
