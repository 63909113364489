import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatBotApi } from "../api/chatBotApi";
import { chatBotActions } from "../../slice";

export const getGptModels = createAsyncThunk(
  "chatBot/getGptModels",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(chatBotActions.isError(null));
      const res = await chatBotApi.getGptModels();
      return { gptModels: res };
    } catch (e) {
      dispatch(chatBotActions.isError(e));
      rejectWithValue(e);
    }
  }
);
