import { memo } from "react";
import s from "./Button.module.css";
import { TButton, BtnColor, BtnSize, BtnText } from "./types";
import { classNames } from "../../lib/classNames/classNames";
import { NavLink } from "react-router-dom";
import Loader from "shared/assets/icons/loader";

const BUTTON_LOADER_COLOR = {
  [BtnColor.BLUE]: "white",
  [BtnColor.WHITE]: "brand",
  [BtnColor.CLEAR]: "brand",
  [BtnColor.RED]: "white",
  [BtnColor.GRADIENT]: "white",
  [BtnColor.GRAY]: "white",
  [BtnColor.BLACK]: "white",
} as const;
// TODO PLEASE вынести комопонент кнопки в другой компонент
const Button = memo(
  ({
    color = BtnColor.BLUE,
    onClick,
    disabled,
    isFull,
    children,
    href,
    size = BtnSize.MEDIUM,
    className,
    to,
    actived,
    isLoading,
    type = "button",
    square,
    actioned,
    textAlign = BtnText.CENTER,
    animated = true,
    download = false,
    target = "_self",
    id,
    ...propsOther
  }: TButton) => {
    const mods = {
      [s.w100]: isFull,
      [s.actived]: actived,
      [s.square]: square,
      [s.actioned]: actioned,
      [s.animated]: animated,
    };
    const extraClasses = [s[color], s[size], s[textAlign], className];
    const Tag = href ? "a" : "button";
    const props = {
      disabled: disabled || isLoading,
      className: classNames(s.button, mods, extraClasses),
      onClick: (e: any) => !disabled && onClick && onClick(e),
      download: download,
      tabIndex: 0,
    };
    return (
      <>
        {to ? (
          <NavLink
            to={to}
            {...props}
            className={({ isActive }: { isActive: boolean }) =>
              classNames(
                s.button,
                { ...mods, [s.activeLink]: isActive },
                extraClasses
              )
            }
            end
          >
            {children}
          </NavLink>
        ) : (
          <Tag
            {...props}
            {...propsOther}
            type={type}
            href={href}
            target={href && target ? target : undefined}
            id={id}
          >
            {isLoading ? (
              <Loader color={"gray"} /> // BUTTON_LOADER_COLOR[color]
            ) : (
              children
            )}
          </Tag>
        )}
      </>
    );
  }
);

export default Button;
