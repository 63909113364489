import { Suspense } from "react";
import Preloader from "../../../../../shared/ui/Preloader/Preloader";
import { Route, RouteProps } from "react-router-dom";

const renderRoute = (
  path: RouteProps["path"],
  element: RouteProps["element"]
) => (
  <Route
    key={path}
    path={path}
    element={
      // <Suspense fallback={<Preloader isContentPreloader />}>
      element
      // {/* </Suspense> */}
    } //isContentPreloader
  />
);
const getRoutes = ({
  routes,
  layoutPath,
  layout,
}: {
  routes: RouteProps[];
  layoutPath?: string;
  layout?: JSX.Element;
}) => {
  return (
    <>
      {layoutPath ? (
        <Route path={layoutPath} element={layout}>
          {routes.map(({ path, element }) => renderRoute(path, element))}
        </Route>
      ) : (
        routes.map(({ path, element }) => renderRoute(path, element))
      )}
    </>
  );
};
export default getRoutes;
