import { createAsyncThunk } from "@reduxjs/toolkit";
import foldersApi from "../api/foldersApi";
import { chatBotActions } from "../../slice";

export const createNewFolder = createAsyncThunk(
  "chatBot/createNewFolder",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(chatBotActions.isError(null));
      const folder = await foldersApi.createFolder();

      return { folder };
    } catch (e: any) {
      dispatch(chatBotActions.isError(e));
      return rejectWithValue(e.message);
    }
  }
);
