import useHttp from "shared/api/services/api";
import { API_PREFIX } from "shared/api/constants/api";

export default class audiosApi {
  static http = useHttp(API_PREFIX.AUDIOS);

  static async getAllAudios() {
    const { data } = await audiosApi.http.get("");
    return data;
  }

  static async getAudio(id: string) {
    const { data } = await audiosApi.http.get(`/${id}`);
    return data;
  }
  static async deleteAudio(id: string) {
    const { data } = await audiosApi.http.delete(`/${id}`);
    return data;
  }
  static async getVoice(audioId: string) {
    const { data } = await audiosApi.http.get(`/${audioId}/voice`);
    return data;
  }

  static async voiceToText(voice: any) {
    const { data } = await audiosApi.http.post(
      `/voice_to_text`,
      {
        voice,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  }
  static async retryVoiceToText(id: string, voice: any) {
    const { data } = await audiosApi.http.post(
      `/${id}/retry_voice_to_text`,
      { voice },
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return data;
  }

  static async searchTranscript(pattern: string) {
    const { data } = await audiosApi.http.post(`/search`, { pattern });
    return data;
  }
}
