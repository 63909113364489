import { createAsyncThunk } from "@reduxjs/toolkit";
import { tariffsActions } from "../../../slice";
import subPlansApi from "../../api/subPlansApi";
import { TTariff } from "../../../slice/types";
import { PLANS, PLANS_DISCOUNTS } from "pages/content/Tariffs/constants/plans";

export const getSubPlansList = createAsyncThunk(
  "tariffs/getSubPlansList",
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(tariffsActions.isLoading(true));
    dispatch(tariffsActions.isError(false));
    try {
      const plans = await subPlansApi.getSubPlansList();
      // const subscriptions: TTariff[] = MONTHLY_PLANS.map((plan, i) => {
      //   return i === 0
      //     ? plan
      //     : {
      //         ...plans.monthly[i],
      //         ...plan,
      //         annual_id: plans.annual[i - 1].id,
      //         annual_update_rate: plans.annual[i - 1].update_rate,
      //       };
      // });
      // const subscriptions: TTariff[] = MONTHLY_PLANS.map((plan, i) => ({
      //   ...plans.monthly[i],
      //   ...plan,
      // }));
      const subscriptions: TTariff[] = plans.monthly.map(
        (plan: TTariff, i: number) => ({
          ...plan,
          ...PLANS[plan.title as keyof typeof PLANS],
          discount:
            // @ts-ignore
            PLANS_DISCOUNTS["monthly"][
              plan.title as keyof typeof PLANS_DISCOUNTS
            ],
        })
      );
      // console.log(plans);
      return { plans, subscriptions };
    } catch (e) {
      dispatch(tariffsActions.isError(e));
      rejectWithValue(e);
    } finally {
      dispatch(tariffsActions.isLoading(false));
    }
  }
);
