export enum ActionIconColor {
  WHITE = "white",
  SHADOW_WHITE = "shadowWhite",
}

export enum ActionIconSize {
  MEDIUM = "medium",
  DEFAULT = "default",
}

export type TActionIcon = {
  children: React.ReactNode;
  className?: string;
  color: ActionIconColor;
  onClick?: () => void;
  size?: ActionIconSize;
  disabled?: boolean;
};
