import React, { useCallback, useState } from "react";
import { useTheme } from "../../../../app/providers/ThemeProvider";
import s from "./Header.module.css";
import Button from "../../../../shared/ui/Button/Button";
import { BtnColor, BtnSize } from "../../../../shared/ui/Button/types";
import DarkTheme from "../../../../shared/assets/icons/dark";
import Lightning from "../../../../shared/assets/icons/lightning";
import { RoutePath } from "shared/config/routeConfig/routeConfig";
import { SearchBar } from "widgets/SearchBar";
import Menu01 from "shared/assets/icons/menu-01";
import HeaderAvatar from "shared/ui/HeaderAvatar/HeaderAvatar";

interface THeaderProps {
  setSidebarOpen: (value: boolean) => void;
}
export const Header = ({ setSidebarOpen }: THeaderProps) => {
  const { toggleTheme, theme } = useTheme();

  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState<any | null>(null);

  const handleSearch = useCallback((value: string) => {
    setSearchResults(["Документ 1", "Документ 2", "Документ 3"] as any);
  }, []);
  // todo refactor
  return (
    <div className={s.header}>
      <div className="flex w-full items-center justify-between gap-3">
        <SearchBar
          className={s.searchBar}
          value={search}
          onChange={setSearch}
          handleSearch={handleSearch}
          searchResultsLength={searchResults ? searchResults.length : 0}
          loading={false}
          setSearchResults={setSearchResults}
          isSearchResults={Boolean(searchResults)}
          searchItems={(searchResults || []).map((item: any) => (
            <div key={item} className="p-3">
              {item}
            </div>
          ))}
          isDropDown
          disabled
        />

        <div className={s.buttonGroup}>
          <Button
            color={BtnColor.GRADIENT}
            size={BtnSize.XXSMALL}
            animated={false}
            href={"https://old.monoai.ru/login"}
            target="_blank"
            className={s.oldSiteBtn}
          >
            Перейти на старую версию сайта
          </Button>
          <Button
            color={BtnColor.BLACK}
            size={BtnSize.XXSMALL}
            className={s.buttonPlane}
            to={RoutePath.TARIFFS}
          >
            <Lightning />
            Выбрать план
          </Button>

          <button onClick={toggleTheme} className={s.buttonTheme}>
            <DarkTheme />
          </button>
          <HeaderAvatar />
        </div>
        <Button
          color={BtnColor.CLEAR}
          size={BtnSize.XXSMALL}
          onClick={() => setSidebarOpen(true)}
          className={s.burgerMenu}
        >
          <Menu01 />
        </Button>
      </div>
    </div>
  );
};
