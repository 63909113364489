const Logo = (props) => (
  <svg
    width="152"
    height="24"
    viewBox="0 0 152 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0129 0L11.7439 10.838L5.47491 0H0.0503311H0V24H4.69757V8.03386L11.4151 19.6465H12.0728L18.7903 8.03386V24H23.4879V0H23.4375H18.0129Z"
      fill="var(--text-primary-color)"
    />
    <path
      d="M38.5314 0C31.891 0 26.5078 5.37265 26.5078 12C26.5078 18.6273 31.891 24 38.5314 24C45.1717 24 50.5549 18.6273 50.5549 12C50.5549 5.37265 45.1717 0 38.5314 0ZM38.5314 19.3116C34.4859 19.3116 31.2054 16.0376 31.2054 12C31.2054 7.96242 34.4859 4.68837 38.5314 4.68837C42.5769 4.68837 45.8573 7.96242 45.8573 12C45.8573 16.0376 42.5769 19.3116 38.5314 19.3116Z"
      fill="var(--text-primary-color)"
    />
    <path
      d="M72.1408 0H67.4433V14.294L57.415 0H53.5742V24H58.2718V9.3946L68.5181 24H72.1408V0Z"
      fill="var(--text-primary-color)"
    />
    <path
      d="M87.1847 0C80.5443 0 75.1611 5.37265 75.1611 12C75.1611 18.6273 80.5443 24 87.1847 24C93.825 24 99.2082 18.6273 99.2082 12C99.2082 5.37265 93.825 0 87.1847 0ZM87.1847 19.3116C83.1392 19.3116 79.8587 16.0376 79.8587 12C79.8587 7.96242 83.1392 4.68837 87.1847 4.68837C91.2302 4.68837 94.5107 7.96242 94.5107 12C94.5107 16.0376 91.2302 19.3116 87.1847 19.3116Z"
      fill="var(--text-primary-color)"
    />
    <path
      d="M136.156 8.93023C134.44 2.0093 131.197 0 125.605 0C120.012 0 116.804 2.0093 115.053 8.93023C113.301 15.8512 111.288 24 111.288 24H117.328C117.328 24 120.607 10.9395 121.093 8.93023C121.578 6.92093 122.808 2.0093 125.605 2.0093C128.401 2.0093 129.595 6.92093 130.116 8.93023C130.638 10.9395 133.881 24 133.881 24H139.921C139.921 24 137.872 15.8512 136.156 8.93023Z"
      fill="#2871FF"
    />
    <path d="M152 0H145.96V24H152V0Z" fill="#2871FF" />
  </svg>
);

export default Logo;
