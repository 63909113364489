import { createAsyncThunk } from "@reduxjs/toolkit";
import SupportApi from "../../api/supportApi";
import { ServerMessage } from "../../types/supportDataTypes";

export const loadChatMessagesAT = createAsyncThunk<
  { messages: ServerMessage[]; direction: "up" | "down" },
  {
    chatId: string;
    offset: number;
    limit: number;
    direction: "up" | "down";
    isAdmin: boolean;
  }
>("support/loadChatMessages", async (arg, thunkApi) => {
  try {
    const { chatId, offset, limit, isAdmin } = arg;
    let data: ServerMessage[];

    if (isAdmin) {
      data = await SupportApi.getChatsMessagesAdmin({ chatId, offset, limit });
    } else {
      data = await SupportApi.getChatsMessagesUser({ chatId, offset, limit });
    }

    return { messages: data, direction: arg.direction };
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});
