import { createAsyncThunk } from "@reduxjs/toolkit";
import { TDocument } from "../../slice/types";
import { textDocumentsActions } from "../../slice";
import foldersApi from "../api/folderApi";

export const deleteFolder = createAsyncThunk(
  "textDocuments/deleteFolder",
  async ({ folder_id }: { folder_id: string }, thunkAPI) => {
    const { rejectWithValue, getState, dispatch } = thunkAPI;
    // dispatch(textDocumentsActions.isLoading(true));

    try {
      dispatch(textDocumentsActions.isError(null));
      const res: TDocument = await foldersApi.deleteFolder(folder_id);

      return { deletedFolderId: folder_id };
    } catch (e) {
      dispatch(textDocumentsActions.isError(e));
      rejectWithValue(e);
    } finally {
      // dispatch(textDocumentsActions.isLoading(false));
    }
  }
);
