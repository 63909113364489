import React, { useState, useRef, useEffect } from "react";
import s from "./Select.module.scss";
import ChevronDown from "../../assets/icons/chevron-down";
import ChevronUp from "../../assets/icons/chevron-up";

export interface Option {
  label: string;
  value: string;
}

interface SelectProps {
  options: Option[];
  selectedOption?: Option;
  onSelect: (option: Option) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}

export const Select: React.FC<SelectProps> = ({
  options,
  selectedOption,
  onSelect,
  placeholder = "Выберите вариант",
  disabled = false,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleSelectOption = (option: Option) => {
    onSelect(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`${s.dropdown} ${className}`} ref={selectRef}>
      <button
        className={s.dropdown__button}
        onClick={() => !disabled && setIsOpen(!isOpen)}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        disabled={disabled}
      >
        {selectedOption?.label || placeholder}
        {isOpen ? (
          <ChevronUp width="16px" height="16px" />
        ) : (
          <ChevronDown width="16px" height="16px" />
        )}
      </button>
      {isOpen && (
        <ul className={s.dropdown__content} role="listbox">
          {options.map((option) => (
            <li
              key={option.value}
              className={s.dropdown__item}
              onClick={() => handleSelectOption(option)}
              role="option"
              aria-selected={selectedOption?.value === option.value}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
