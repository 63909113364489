import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import CardBacking from "shared/ui/CardBacking/CardBacking";
import { debounce } from "shared/lib/debounce/debounce";
import Loader from "../../../../shared/assets/icons/loader";
import ChevronRight from "shared/assets/icons/chevron-right";
import Command from "shared/assets/icons/command";
import s from "./SearchBar.module.css";
import { classNames } from "shared/lib/classNames/classNames";
export interface TSearchBar {
  value: string;
  onChange: (value: string) => void;
  handleSearch: (value: string) => void;
  loading: boolean;
  placeholder?: string;
  searchItems: React.ReactNode;
  searchResultsLength: number;
  isDropDown?: boolean;
  setSearchResults: (value: string[]) => void;
  error?: boolean;
  isSearchResults: boolean;
  className?: string;
  disabled?: boolean;
}
export const SearchBar = memo(
  ({
    value,
    onChange,
    handleSearch,
    loading = false,
    placeholder = "Поиск шаблонов и документов...",
    searchResultsLength,
    searchItems,
    isDropDown = false,
    setSearchResults,
    error = false,
    isSearchResults = false,
    className = "",
    disabled = false,
  }: TSearchBar) => {
    const [focused, setFocused] = React.useState(false);
    const searchRef = React.useRef<HTMLInputElement>(null);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const userAgent = navigator.userAgent || navigator.vendor;
      // Функция для проверки типа устройства
      const checkIfMobile = () => {
        return /Mobi|Android/i.test(userAgent);
      };
      setIsMobile(checkIfMobile());
    }, []);

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        // Проверяем, нажаты ли Command (или Ctrl) и K одновременно
        if ((event.ctrlKey || event.metaKey) && event.key === "k") {
          event.preventDefault();
          searchRef.current?.focus();
        }
      };
      const handleClickOutside = (event: MouseEvent) => {
        if (
          searchRef.current &&
          !searchRef.current.contains(event.target as Node)
        ) {
          setFocused(false);
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
        document.removeEventListener("click", handleClickOutside);
      };
    }, []);
    const debouncedSearch = useCallback(
      debounce((value: string) => {
        if (value.length > 0) {
          handleSearch(value);
        }
      }, 1500),
      []
    );

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      onChange?.(value);
      debouncedSearch(value);
    };
    return (
      <div
        className={classNames(s.container, {}, [className])}
        onClick={(e: any) => e.stopPropagation()}
      >
        <div>
          <div className={s.inputContainer}>
            <input
              type="search"
              id="search-input"
              placeholder={placeholder}
              className={s.input}
              onFocus={() => setFocused(true)}
              ref={searchRef}
              value={value}
              onChange={handleInputChange}
              disabled={disabled}
            />
            <div className={s.iconGroup}>
              {loading ? (
                <Loader />
              ) : focused ? (
                <button
                  onClick={() => {
                    onChange("");
                    setSearchResults([]);
                    setFocused(false);
                  }}
                >
                  <ChevronRight />
                </button>
              ) : (
                <div className={classNames(s.iconGroup, { hidden: isMobile })}>
                  <div className={s.icon}>
                    <Command />
                  </div>
                  <div className={s.icon}>K</div>
                </div>
              )}
            </div>
          </div>
        </div>
        {isSearchResults && (
          <CardBacking
            className={classNames(s.searchBar, {
              "absolute top-[58px] z-40": isDropDown,
            })}
          >
            <h3 className="border-b border-gray-200 py-6 px-3 font-bold">
              Результаты поиска
            </h3>
            {searchResultsLength > 0 && focused ? (
              <div>{searchItems}</div>
            ) : error ? (
              <div className="p-3">
                <h4>Произошла ошибка</h4>
              </div>
            ) : (
              <div className="p-3">
                <h4>По вашему запросу ничего не найдено</h4>
              </div>
            )}
          </CardBacking>
        )}
      </div>
    );
  }
);
