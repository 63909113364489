import ratio_1_1 from "shared/assets/icons/Ratio_1_1.svg";
import ratio_16_9 from "shared/assets/icons/Ratio_16_9.svg";
import ratio_4_3 from "shared/assets/icons/Ratio_4_3.svg";
import ratio_3_2 from "shared/assets/icons/ratio_3_2.svg";
import { BgType } from "../model/types/TBackgroundReplacementSchema";

export const BACKGROUND_TYPES = {
  TEXTURE: {
    label: "Текстура",
    backgrounds: [
      {
        label: "Surprise me",
        thumbnail: "https://pebblely.b-cdn.net/samples/surprise-me.png",
      },
      {
        label: "Studio",
        thumbnail:
          "https://pebblely.b-cdn.net/samples/pebblely%20-%202023-03-17T164517.910.jpg",
      },
      {
        label: "Silk",
        thumbnail:
          "https://pebblely.b-cdn.net/samples/pebblely%20-%202023-03-18T161959.621.jpg",
      },
      {
        label: "Light Wood",
        thumbnail:
          "https://pebblely.b-cdn.net/samples/thumbnail-light-wood.jpg",
      },
      {
        label: "Dark Wood",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-dark-wood.jpg",
      },
      {
        label: "Marble",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-marble.jpg",
      },
      {
        label: "Park Bench",
        thumbnail:
          "https://pebblely.b-cdn.net/samples/thumbnail-park-bench.jpg",
      },
      {
        label: "Fruits",
        thumbnail:
          "https://pebblely.b-cdn.net/samples/sample_20221226_013404_15.jpg",
      },
      {
        label: "Paint",
        thumbnail:
          "https://pebblely.b-cdn.net/samples/sample_20221226_013926_38.jpg",
      },
      {
        label: "Pebbles",
        thumbnail:
          "https://pebblely.b-cdn.net/samples/sample_20221226_015811_32.jpg",
      },
      {
        label: "Gold",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-gold.jpg",
      },
    ],
  },
  FLOWERS: {
    label: "Цветы",
    backgrounds: [
      {
        label: "Tabletop",
        thumbnail:
          "https://pebblely.b-cdn.net/samples/pebblely%20-%202023-03-17T160622.039.jpg",
      },
      {
        label: "Roses",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-roses.jpg",
      },
      {
        label: "Lavender",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-lavender.jpg",
      },
      {
        label: "Monstera",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-monstera.jpg",
      },
      {
        label: "Meadow",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-meadow.jpg",
      },
      {
        label: "Dried Flowers",
        thumbnail:
          "https://pebblely.b-cdn.net/samples/thumbnail-dried-flowers.jpg",
      },
      {
        label: "Baby's Breath",
        thumbnail:
          "https://pebblely.b-cdn.net/samples/thumbnail-babys-breath.jpg",
      },
    ],
  },
  HOLIDAYS: {
    label: "Праздники",
    backgrounds: [
      {
        label: "Gifts",
        thumbnail: "https://pebblely.b-cdn.net/samples/gifts_sample.jpg",
      },
      {
        label: "Christmas",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-christmas.jpg",
      },
      {
        label: "Wedding",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-wedding.jpg",
      },
      {
        label: "Halloween",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-halloween.jpg",
      },
      {
        label: "Easter",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-easter-2.jpg",
      },
    ],
  },
  NATURE: {
    label: "Природа",
    backgrounds: [
      {
        label: "Outdoors",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-outdoors.jpg",
      },
      {
        label: "Nature",
        thumbnail:
          "https://pebblely.b-cdn.net/samples/sample_20221226_013645_20.jpg",
      },
      {
        label: "Beach",
        thumbnail:
          "https://pebblely.b-cdn.net/samples/sample_20221226_015530_43.jpg",
      },
      {
        label: "Skyscraper",
        thumbnail:
          "https://pebblely.b-cdn.net/samples/thumbnail-skyscraper.jpg",
      },
      {
        label: "Fire",
        thumbnail: "https://pebblely.b-cdn.net/samples/fire_sample.jpg",
      },
      {
        label: "Water",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-water.jpg",
      },

      {
        label: "Snow",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-snow.jpg",
      },
    ],
  },
  INTERIOR: {
    label: "Интерьер",
    backgrounds: [
      {
        label: "Cafe",
        thumbnail:
          "https://pebblely.b-cdn.net/samples/sample_20221226_015008_22.jpg",
      },
      {
        label: "Kitchen",
        thumbnail:
          "https://pebblely.b-cdn.net/samples/sample_20230130_111500_1.jpg",
      },
      {
        label: "Bathroom",
        thumbnail:
          "https://pebblely.b-cdn.net/samples/sample_20230204_1600_1.jpg",
      },
      {
        label: "Modern Interior",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-modern.jpg",
      },
      {
        label: "Minimalist Interior",
        thumbnail:
          "https://pebblely.b-cdn.net/samples/thumbnail-minimalist.jpg",
      },
      {
        label: "Japanese Interior",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-japanese.jpg",
      },
      {
        label: "Scandi Interior",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-scandi.jpg",
      },
      {
        label: "Luxury Interior",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-luxury.jpg",
      },
      {
        label: "Coastal Interior",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-coastal.jpg",
      },
      {
        label: "Bohemian Interior",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-bohemian.jpg",
      },
      {
        label: "Farmhouse Interior",
        thumbnail: "https://pebblely.b-cdn.net/samples/thumbnail-farmhouse.jpg",
      },
    ],
  },
} as const;

export const BG_TYPES_BUTTONS = [
  {
    id: BgType.SELECTION,
    title: "Готовый",
  },
  {
    id: BgType.GENERATION,
    title: "Генерация",
  },
] as const;

export const ASPECT_RATIOS = [
  {
    label: "1:1",
    icon: ratio_1_1,
  },
  {
    label: "4:3",
    icon: ratio_4_3,
  },
  {
    label: "3:2",
    icon: ratio_3_2,
  },

  {
    label: "16:9",
    icon: ratio_16_9,
  },
];

export const IMAGE_SIZES = {
  "1:1": ["512 × 512", "1024 × 1024", "1536 × 1536", "2048 × 2048"],
  "4:3": [
    "682 × 512",
    "1024 × 768",
    "1365 × 1024",
    "1536 × 1152",
    "2048 × 1536",
    "2730 × 2048",
  ],
  "3:2": [
    "768 × 512",
    "1024 × 682",
    "1536 × 1024",
    "2304 × 1536",
    "2048 × 1365",
    "3072 × 2048",
  ],
  "16:9": [
    "910 × 512",
    "1024 × 576",
    "1820 × 1024",
    "1536 × 864",
    "2730 × 1536",
    "2048 × 1152",
    "3640 × 2048",
  ],
} as const;
