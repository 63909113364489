import s from "./Footer.module.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className={s.footer}>
      <p>Все права защищены © {currentYear} MonoAi.</p>
      <p>Версия: 2.0</p>
    </div>
  );
};

export default Footer;
