import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatBotApi } from "../api/chatBotApi";
import foldersApi from "../api/foldersApi";
import { chatBotActions } from "../../slice";

export const deleteChatFromFolder = createAsyncThunk(
  "chatBot/deleteChatFromFolder",
  async (arg: { entity_id: string; folder_id: string }, { dispatch }) => {
    try {
      // const res = await
      dispatch(chatBotActions.isError(null));
      foldersApi.deleteChatFromFolder({
        chat_id: arg.entity_id,
        folder_id: arg.folder_id,
      });
      return { chat_id: arg.entity_id, folder_id: arg.folder_id };
    } catch (e) {
      dispatch(chatBotActions.isError(e));
    }
  }
);
