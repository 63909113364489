import { createAsyncThunk } from "@reduxjs/toolkit";
import foldersApi from "../api/foldersApi";
import { chatBotActions } from "../../slice";

export const updateFolder = createAsyncThunk(
  "chatBot/updateFolder",
  async (
    { folder_id, folderName }: { folder_id: string; folderName: string },
    { rejectWithValue, dispatch }
  ) => {
    // dispatch(chatBotActions.isLoading(true));
    try {
      dispatch(chatBotActions.isError(null));
      const res = await foldersApi.updateFolder(folder_id, folderName);

      return { updatedFolder: res };
    } catch (e) {
      dispatch(chatBotActions.isError(e));
      rejectWithValue(e);
    } finally {
      // dispatch(chatBotActions.isLoading(false));
    }
  }
);
