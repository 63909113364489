import { createAsyncThunk } from "@reduxjs/toolkit";
import documentsApi from "../api/documentsApi";
import { textDocumentsActions } from "../../slice";
import { TDocument } from "../../slice/types";

export const createNewDocument = createAsyncThunk(
  "textDocuments/createNewDocument",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      dispatch(textDocumentsActions.isError(null));
      // dispatch(textDocumentsActions.isLoading(true));
      const data: TDocument = await documentsApi.createDocument();
      const document = {
        ...data,
        body: data.body || {},
      };

      return { document };
    } catch (e: any) {
      dispatch(textDocumentsActions.isError(e));
      return rejectWithValue(e.message);
    } finally {
      // dispatch(textDocumentsActions.isLoading(false));
    }
  }
);
