const IcGradientFile03 = () => (
  <svg
    width="29"
    height="36"
    viewBox="0 0 29 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.11111 2V34M16.2222 9.11111H19.7778M16.2222 16.2222H19.7778M3.77778 2H23.3333C25.297 2 26.8889 3.59188 26.8889 5.55556V26.8889C26.8889 28.8526 25.297 30.4444 23.3333 30.4444H3.77778C2.79594 30.4444 2 29.6485 2 28.6667V3.77778C2 2.79594 2.79594 2 3.77778 2Z"
      stroke="url(#paint0_linear_6097_134449)"
      strokeOpacity="0.72"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6097_134449"
        x1="1.64444"
        y1="2"
        x2="26.5333"
        y2="34"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0084FF" />
        <stop offset="0.824" stopColor="#C974FE" />
        <stop offset="1" stopColor="#C974FE" />
      </linearGradient>
    </defs>
  </svg>
);

export default IcGradientFile03;
