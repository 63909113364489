import axios from "axios";
import useHttp from "shared/api/services/api";
import { API_PREFIX, AUTH_URL } from "shared/api/constants/api";
import {
  TLoginData,
  TRegisterResponse,
  TRegUserData,
  TLoginResponse,
} from "./types";
import { LS_ACCESS_TOKEN } from "shared/api/constants/localStorage";

const accessToken = localStorage.getItem(LS_ACCESS_TOKEN);
export default class AuthApi {
  static http = useHttp(API_PREFIX.AUTH);

  static async register(userData: TRegUserData): Promise<TRegisterResponse> {
    const { data } = await AuthApi.http.post(`/register`, userData);
    return data;
  }

  static async login(userData: TLoginData): Promise<TLoginResponse> {
    const { data } = await axios.post(`${AUTH_URL}/login`, userData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    return data;
  }

  static async logout() {
    const { data } = await AuthApi.http.post(`/logout`);
    return data;
  }

  static async updatePassword(old_password: string, new_password: string) {
    const { data } = await AuthApi.http.post("/change-password", {
      old_password,
      new_password,
    });

    return data;
  }

  static async sendResetLink(email: string) {
    const { data } = await AuthApi.http.post(`/forgot-password`, { email });
    return data;
  }

  static async resetPassword(password: string, token: string) {
    const { data } = await AuthApi.http.post(`/reset-password`, {
      password,
      token,
    });
    return data;
  }
  static async requestVerifyToken(email: string) {
    const { data } = await AuthApi.http.post(`/request-verify-token`, {
      email,
    });
    return data;
  }

  static async verify(token: string) {
    const { data } = await AuthApi.http.post(`/verify`, { token });
    return data;
  }

  static async requestVerificationCode(email: string) {
    const { data } = await AuthApi.http.post(`/request-verification-code`, {
      email,
    });
    return data;
  }
  static async verifyCode(email: string, code: string) {
    const { data } = await AuthApi.http.post(`/verify-code?code=${code}`, {
      email,
    });
    return data;
  }
}
