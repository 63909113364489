import { createSlice } from "@reduxjs/toolkit";
import {
  BgType,
  TBackgroundReplacementSchema,
} from "../types/TBackgroundReplacementSchema";
import { backgroundReplacementThunks } from "../services/thunks";
import {
  ASPECT_RATIOS,
  BACKGROUND_TYPES,
  IMAGE_SIZES,
} from "../../constants/constants";

const initialState: TBackgroundReplacementSchema = {
  currentImage: null,
  isLoading: false,
  isError: false,
  allReplacements: null,
  offset: 0,
  isGenerating: false,
  isGettingMoreReplacements: false,
  hasMoreReplacements: true,

  bgReplacementType: BgType.SELECTION,
  selectionBgTab: "TEXTURE",
  selectionBgLabel: "none",
  aspectRatio: ASPECT_RATIOS[0],
  // @ts-ignore
  imgSize: IMAGE_SIZES[ASPECT_RATIOS[0].label][0],
};

const backgroundReplacementSlice = createSlice({
  name: "bgReplacement",
  initialState,
  reducers: {
    isLoading(state, { payload }) {
      state.isLoading = payload;
    },
    isError(state, { payload }) {
      state.isError = payload;
    },
    setIsGenerating(state, { payload }) {
      state.isGenerating = payload;
    },
    setOffset(state, { payload }) {
      state.offset = payload;
    },
    setIsGettingMoreReplacements(state, { payload }) {
      state.isGettingMoreReplacements = payload;
    },
    setHasMoreReplacements(state, { payload }) {
      state.hasMoreReplacements = false;
    },

    setBgReplacementType(state, { payload }) {
      state.bgReplacementType = payload;
    },
    setSelectionBgTab(state, { payload }) {
      state.selectionBgTab = payload;
    },
    setSelectionBgLabel(state, { payload }) {
      state.selectionBgLabel = payload;
    },
    setAspectRatio(state, { payload }) {
      state.aspectRatio = payload;

      // @ts-ignore
      state.imgSize = IMAGE_SIZES[payload.label][0];
    },
    setImgSize(state, { payload }) {
      state.imgSize = payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(
        backgroundReplacementThunks.getAllReplacements.fulfilled,
        (state, { payload }) => {
          if (payload) {
            if (payload.isMore) {
              state.allReplacements = [
                ...(state.allReplacements || []),
                ...payload.replacements,
              ];
            } else {
              state.allReplacements = payload.replacements;
            }
          }
        }
      )
      .addCase(
        backgroundReplacementThunks.removeBackground.fulfilled,
        (state, { payload }) => {
          if (payload) {
            state.currentImage = payload?.replacement;
          }
        }
      )
      .addCase(
        backgroundReplacementThunks.createBackground.fulfilled,
        (state, { payload }) => {
          state.allReplacements = [
            payload?.newImg,
            ...(state.allReplacements || []),
          ];
          state.isGenerating = false;
        }
      );
  },
});

export const backgroundReplacementReducer = backgroundReplacementSlice.reducer;
export const backgroundReplacementActions = backgroundReplacementSlice.actions;
