const IcGradientStars = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_6177_207447)">
      <path
        d="M12 40V32M12 16V8M8 12H16M8 36H16M25.6 9.6L22.8253 16.8142C22.3741 17.9873 22.1485 18.5739 21.7976 19.0673C21.4867 19.5046 21.1046 19.8867 20.6673 20.1976C20.1739 20.5485 19.5873 20.7741 18.4142 21.2253L11.2 24L18.4142 26.7747C19.5873 27.2259 20.1739 27.4515 20.6673 27.8024C21.1046 28.1133 21.4867 28.4954 21.7976 28.9327C22.1485 29.4261 22.3741 30.0127 22.8253 31.1858L25.6 38.4L28.3747 31.1858C28.8259 30.0127 29.0515 29.4261 29.4024 28.9327C29.7133 28.4954 30.0954 28.1133 30.5327 27.8024C31.0261 27.4515 31.6127 27.2259 32.7858 26.7747L40 24L32.7858 21.2253C31.6127 20.7741 31.0261 20.5485 30.5327 20.1976C30.0954 19.8867 29.7133 19.5046 29.4024 19.0673C29.0515 18.5739 28.8259 17.9873 28.3747 16.8142L25.6 9.6Z"
        stroke="url(#paint0_linear_6177_207447)"
        strokeOpacity="0.72"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_6177_207447"
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6177_207447"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6177_207447"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_6177_207447"
        x1="8"
        y1="8"
        x2="40"
        y2="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0084FF" />
        <stop offset="0.75" stopColor="#C974FE" />
        <stop offset="1" stopColor="#C974FE" />
      </linearGradient>
    </defs>
  </svg>
);

export default IcGradientStars;
