import React, { useRef, useState } from "react";
import s from "./HeaderAvatar.module.css";
import UserAvatar, { AvatarSize } from "../UserAvatar/UserAvatar";
import { RoutePath } from "shared/config/routeConfig/routeConfig";
import { LS_ACCESS_TOKEN } from "shared/api/constants/localStorage";
import AuthApi from "features/Auth/services/AuthApi";
import Modal from "../Modal/Modal";
import { useSelector } from "react-redux";
import { userSelector } from "entities/User/model/selectors";
import UserBalance from "../UserBalance/UserBalance";
import { AppLink } from "../AppLink/AppLink";
import { TTheme, useTheme } from "app/providers/ThemeProvider";
import ActionIcon from "../ActionIcon/ActionIcon";
import { ActionIconColor } from "../ActionIcon/types";
import DarkTheme from "shared/assets/icons/dark";
import { classNames } from "shared/lib/classNames/classNames";
const HeaderAvatar = ({
  placement = "right",
  mobilePlacement = "mobileLeft",
}: {
  placement?: "left" | "right";
  mobilePlacement?: "mobileLeft" | "mobileRight";
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const btnRef = useRef<HTMLButtonElement>(null);
  const userData = useSelector(userSelector.currentUser);

  const { toggleTheme, theme } = useTheme();

  const toggleDropdown = () => setIsDropdownOpen((p) => !p);

  const onClose = () => setIsDropdownOpen(false);

  const logout = async () => {
    await AuthApi.logout();
    localStorage.removeItem(LS_ACCESS_TOKEN);
    // window.location.reload();
    window.location.assign(RoutePath.LOGIN);
    // dispatch(logoutAction());
    // navigate(RoutePath.LOGIN);
    // window.location.href = 'https://example.com/logout';   but a tag
  };

  const LINKS = [
    {
      path: RoutePath.TARIFFS,
      title: "План подписок",
    },
    // {
    //   path: "",
    //   title: "Заявки",
    // },
    {
      path: RoutePath.SETTINGS,
      title: "Настройки",
    },
  ];

  return (
    <div className={s.avatarContainer}>
      <button onClick={toggleDropdown} ref={btnRef}>
        <UserAvatar size={AvatarSize.MEDIUM} />
      </button>
      {isDropdownOpen && (
        <Modal
          isOpen={isDropdownOpen}
          onClose={onClose}
          className={classNames(
            s.dropdownMenu,
            {
              [s[placement]]: placement,
              [s[mobilePlacement]]: mobilePlacement,
            },
            []
          )}
          anchorEl={btnRef}
        >
          <div className={s.dropdownItem + " gap-6"}>
            <p className={s.accountName}>
              {userData?.name} {userData?.surname}
            </p>
            <p className={s.mail}>{userData?.email}</p>
          </div>
          <div className={s.dropdownItem}>
            <UserBalance />
          </div>
          <div className={s.dropdownItemBtn}>
            {LINKS.map((link) => (
              <AppLink key={link.path} to={link.path} onClick={toggleDropdown}>
                {link.title}
              </AppLink>
            ))}

            <button onClick={logout} className="text-gray-500">
              Выйти
            </button>
            <div className={s.mobileThemeBtn}>
              <p>{theme === TTheme.LIGHT ? "Темная тема" : "Светлая тема"}</p>
              <ActionIcon color={ActionIconColor.WHITE} onClick={toggleTheme}>
                <DarkTheme />
              </ActionIcon>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default HeaderAvatar;
