import { FC } from "react";
import s from "./CardBacking.module.css";
import { classNames } from "../../lib/classNames/classNames";

interface TCardBacking {
  height?: string;
  children: JSX.Element | React.ReactNode;
  isMain?: boolean;
  className?: string;
  hasShadow?: boolean;
}
const CardBacking: FC<TCardBacking> = ({
  // height,
  children,
  isMain,
  className = "",
  hasShadow = true,
}) => {
  return (
    <div
      className={classNames(
        s.card,
        { [s.isMain]: isMain, [s.hasShadow]: hasShadow, [s.isMain]: isMain },
        [className]
      )}
    >
      {children}
    </div>
  );
};

export default CardBacking;
