import PageTitle from "shared/ui/PageTitle/PageTitle";
import cn from "./NewRequest.module.scss";
import { classNames } from "shared/lib/classNames/classNames";
import { Option, Select } from "shared/ui/Select/Select";
import React from "react";
import Input from "../../../../../shared/ui/Input/Input";
import Button from "../../../../../shared/ui/Button/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { supportThunks } from "../../model/slice/thunks";
import { useAppDispatch } from "app/providers/StoreProvider/config/store";
import { ToBack } from "../../../../../shared/ui/ToBack/ToBack";
import { useNavigate } from "react-router-dom";
import CardBacking from "shared/ui/CardBacking/CardBacking";

const themeReq: Option[] = [
  { label: "Общий запрос", value: "Общий запрос" },
  { label: "Техническая проблема", value: "Техническая проблема" },
  { label: "Предложения по улучшению", value: "Предложения по улучшению" },
  { label: "Обратная связь", value: "Обратная связь" },
  { label: "Другое", value: "Другое" },
];

const priority: Option[] = [
  { label: "Низкий", value: "Низкий" },
  { label: "Стандартный", value: "Стандартный" },
  { label: "Высокий", value: "Высокий" },
  { label: "Критический", value: "Критический" },
];

type FormValues = {
  selectReq: Option;
  priorityReq: Option;
  textFieldTheme: string;
  textFieldMessage: string;
};

const optionSchema = yup
  .object({
    label: yup.string().required(),
    value: yup.string().required(),
  })
  .required()
  .test(
    "is-not-empty",
    "Пожалуйста, выберите опцию",
    (value) => value && Object.keys(value).length > 0
  );

const schema = yup.object({
  selectReq: optionSchema.required("Категория обязательна для ввода"),
  priorityReq: optionSchema.required("Срочность обязательна для ввода"),
  textFieldTheme: yup
    .string()
    .required("Тема обязательна для ввода")
    .max(300, "Тема не должна превышать 300 символов")
    .min(10, "Тема должна содержать не менее 10 символов"),
  textFieldMessage: yup
    .string()
    .required("Сообщение обязательно для ввода")
    .max(1000, "Сообщение не должно превышать 1000 символов")
    .min(10, "Сообщение должно содержать не менее 10 символов"),
});

type FormDataType = yup.InferType<typeof schema>;

export const NewRequest = () => {
  const classNameReq = {
    root: classNames(cn.root),
    blockSelect: classNames(cn.blockSelect),
    label: classNames(cn.label),
    textField: classNames(cn.textField),
    textArea: classNames(cn.textArea),
    errorMsg: classNames(cn.errorMsg),
  };
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormDataType>({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    defaultValues: {
      selectReq: { label: "Общий запрос", value: "Общий запрос" },
      priorityReq: { label: "Низкий", value: "Низкий" },
      textFieldTheme: "",
      textFieldMessage: "",
    },
  });

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<FormValues> = (data: FormDataType) => {
    dispatch(
      supportThunks.createNewRequestAT({
        model: {
          text: data.textFieldMessage,
          urgency: data.priorityReq.value,
          topic: data.textFieldTheme,
          category: data.selectReq.label,
        },
      })
    )
      .unwrap()
      .then((r) => {
        navigate(-1);
      })
      .catch(() => {
        console.warn("error");
      });

    reset();
  };

  return (
    <>
      <div>
        <ToBack title={"Вернуться к запросам"} />
      </div>
      <PageTitle
        description={
          "Сгенерируйте новый запрос в службу поддержки. Мы ответим как можно скорее"
        }
        title="Создать новое обращение"
        button={<></>}
      />
      <CardBacking>
        <div className={classNameReq.root}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classNameReq.blockSelect}>
              <div style={{ width: "100%" }}>
                <p className={classNameReq.label}>Категория поддержки</p>
                <Controller
                  name="selectReq"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onSelect={field.onChange}
                      selectedOption={field.value}
                      options={themeReq}
                    />
                  )}
                />
                {errors.selectReq && (
                  <span className={classNameReq.errorMsg}>
                    {errors.selectReq.message}
                  </span>
                )}
              </div>
              <div className={classNameReq.label} style={{ width: "100%" }}>
                <p className={classNameReq.label}>Срочность</p>

                <Controller
                  name="priorityReq"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onSelect={field.onChange}
                      selectedOption={field.value}
                      options={priority}
                    />
                  )}
                />
                {errors.priorityReq && (
                  <span className={classNameReq.errorMsg}>
                    {errors.priorityReq.message}
                  </span>
                )}
              </div>
            </div>
            <div>
              <p className={classNameReq.label}>Тема обращения</p>
              <Controller
                name="textFieldTheme"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    id={"1"}
                    className={classNameReq.textField}
                    placeholder="Тема обращения"
                  />
                )}
              />
              {errors.textFieldTheme && (
                <span className={classNameReq.errorMsg}>
                  {errors.textFieldTheme.message}
                </span>
              )}
            </div>
            <div style={{ marginTop: "24px" }}>
              <p className={classNameReq.label}>Сообщение</p>
              <Controller
                name="textFieldMessage"
                control={control}
                render={({ field }) => (
                  <textarea
                    {...field}
                    className={classNameReq.textArea}
                    placeholder="Введите ваше сообщение"
                  />
                )}
              />
              {errors.textFieldMessage && (
                <span className={classNameReq.errorMsg}>
                  {errors.textFieldMessage.message}
                </span>
              )}
            </div>
            <div style={{ marginTop: "24px" }}>
              <Button onClick={handleSubmit(onSubmit)}>Отправить</Button>
            </div>
          </form>
        </div>
      </CardBacking>
    </>
  );
};
