import React, { memo, useEffect, useRef } from "react";
import s from "./Modal.module.css";
import { classNames } from "shared/lib/classNames/classNames";

export interface TModal {
  isOpen: boolean;
  onClose: (e?: any) => void;
  children: React.ReactNode;
  className: string;
  anchorEl?: React.RefObject<HTMLElement> | React.MutableRefObject<null> | null;
}
const Modal = memo(
  ({ isOpen, onClose, children, className, anchorEl }: TModal) => {
    const modalRef = useRef<null | HTMLDivElement>(null);
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        // Проверяем, был ли клик вне области модалки
        if (
          modalRef.current &&
          !modalRef.current.contains(event.target as Node) &&
          !anchorEl?.current?.contains(event.target as Node)
        ) {
          onClose(event);
        }
      };
      // Добавляем обработчик события при открытии модалки
      if (isOpen) {
        document.addEventListener("mousedown", handleClickOutside);
      }

      // Убираем обработчик события при закрытии модалки
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isOpen, onClose]);
    if (!isOpen) return null;
    return (
      <div className={classNames(s.container, {}, [className])} ref={modalRef}>
        {children}
      </div>
    );
  }
);

export default Modal;
