import { useEffect } from "react";
import {
  fetchEventSource,
  EventSourceMessage,
} from "@microsoft/fetch-event-source";
import { useAppDispatch } from "app/providers/StoreProvider/config/store";
import { supportActions } from "../slice";
import { LS_ACCESS_TOKEN } from "shared/api/constants/localStorage";
import { refreshToken } from "shared/api/services/refresh";

export const useStreamMessages = (chatId: string | undefined) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!chatId) return;

    dispatch(supportActions.setStreaming(true));

    const authorizationToken = localStorage.getItem(LS_ACCESS_TOKEN);
    const url = `https://server.monoai.ru/api/v1/support_chats/${chatId}/messages/stream`;

    const controller = new AbortController();

    const handleStream = async (token: string) => {
      try {
        await fetchEventSource(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "text/event-stream",
            Authorization: `Bearer ${token}`,
          },
          signal: controller.signal,
          onmessage: (event: EventSourceMessage) => {
            if (event.data) {
              try {
                const message = JSON.parse(event.data);
                dispatch(supportActions.addMessage(message));
              } catch (err) {
                console.error("Error parsing message:", err);
              }
            }
          },
          onclose() {
            dispatch(supportActions.setStreaming(false));
          },
          onerror: (err) => {
            // Убрали 'async'
            console.error("Stream error:", err);
            controller.abort();
            dispatch(supportActions.setStreaming(false));
          },
          async onopen(response) {
            if (response.ok && response.status === 200) {
              // console.log("Stream connected");
            } else if (response.status === 401) {
              try {
                const newAccessToken = await refreshToken();
                localStorage.setItem(LS_ACCESS_TOKEN, newAccessToken);
                await handleStream(newAccessToken);
              } catch (error) {
                console.error("Error refreshing token:", error);
                controller.abort();
                dispatch(supportActions.setStreaming(false));
              }
            } else if (response.status >= 400 && response.status < 500) {
              console.error("Client side error", response.status);
              controller.abort();
              dispatch(supportActions.setStreaming(false));
            }
          },
        });
      } catch (error) {
        console.error("Error in fetchEventSource:", error);
        dispatch(supportActions.setStreaming(false));
      }
    };

    handleStream(authorizationToken || "").then((r) => r);

    return () => {
      controller.abort();
      dispatch(supportActions.setStreaming(false));
    };
  }, [chatId, dispatch]);
};
