import LimitsModal from "features/LimitsModal/LimitsModal";
import s from "./BgReplacementLimitsModal.module.css";
import { PLANS } from "pages/content/Tariffs/constants/plans";
import React from "react";
import MuiCustomTooltip from "shared/ui/MuiCustomTooltip/ui/MuiCustomTooltip";

const BgReplacementLimitsModal = ({
  isBgLimits,
  setIsBgLimits,
  userPlan,
}: {
  isBgLimits: boolean;
  setIsBgLimits: React.Dispatch<React.SetStateAction<boolean>>;
  userPlan: any;
}) => {
  const profiPlan = PLANS["Профи"];
  const userPlanDescription =
    PLANS[userPlan?.maxUserPlan.title as keyof typeof PLANS];

  const POINTS = [
    {
      prevText: userPlanDescription?.symbols_count + " символов",
      currentText: profiPlan?.symbols_count + " символов",
      extraText: " окно контекста",
      tooltipTitle: "Допустимое количество символов на ввод",
    },
    {
      prevText: userPlanDescription?.files_size + " Мб",
      currentText: profiPlan?.files_size + " Мб",
      extraText: " размер загружаемого файла",
      tooltipTitle: "Максимальный объём одного зайгружаемого файла",
    },
    {
      prevText: userPlanDescription?.voice_cloning_count + " клонирование",
      currentText: profiPlan?.voice_cloning_count + " клонирований",
      extraText: " голоса",
      tooltipTitle: "Возможное количество загруженных Вами голосов",
    },
  ];

  return (
    <LimitsModal
      open={isBgLimits}
      onClose={() => setIsBgLimits(false)}
      title={<>Перейди на следующий уровень и открой Замену фона!</>}
      description={
        <p>
          <span className="text-gray-400">Текущий тариф</span>
          <br />
          <span className="text-gray-950">{userPlan?.maxUserPlan.title}</span>
        </p>
      }
    >
      <div className="flex flex-col gap-6">
        <p className="font-medium text-[18px] p-0">
          Поменяй тариф, сохрани текущие токены
          <br />и получи новые возможности. Замена фона доступна от тарифа
          «Профи»
        </p>
        <div className="text-[14px]">
          {POINTS.map(({ prevText, currentText, extraText, tooltipTitle }) => (
            <p key={prevText}>
              <span className={s.prevDescription}>{prevText}</span>{" "}
              <MuiCustomTooltip title={tooltipTitle}>
                <span className={s.descriptionPreferred + " " + s.dotted}>
                  {currentText}
                </span>
              </MuiCustomTooltip>
              <span> — {extraText}</span>
            </p>
          ))}
          {profiPlan?.gptModels.map((model, idx) => (
            <span
              key={model}
              className={
                !userPlanDescription?.gptModels?.includes(model)
                  ? s.descriptionPreferred
                  : ""
              }
            >
              {model}
              {idx !== profiPlan?.gptModels.length - 1 && ", "}
            </span>
          ))}
          <p className={s.descriptionPreferred}>Замена фона</p>
        </div>
        <div className={s.pricesContainer}>
          <div className={s.price}>
            <p className="text-[18px]">
              <span className="text-[24px]">699 ₽</span> /мес
            </p>
            <span className="text-gray-400">При оплате за месяц</span>
          </div>
          <div className={s.pricePreferred}>
            <p className="text-[18px]">
              <span className="text-[24px]">525 ₽</span> /мес
            </p>
            <span className="text-gray-400">При оплате за месяц</span>
          </div>
        </div>
      </div>
    </LimitsModal>
  );
};

export default BgReplacementLimitsModal;
