import { TStateSchema } from "app/providers/StoreProvider/config/types";

export const bgReplacementSelector = {
  currentImage: (state: TStateSchema) => state.bgReplacement.currentImage,
  isLoading: (state: TStateSchema) => state.bgReplacement.isLoading,
  isError: (state: TStateSchema) => state.bgReplacement.isError,
  allReplacements: (state: TStateSchema) => state.bgReplacement.allReplacements,
  offset: (state: TStateSchema) => state.bgReplacement.offset,
  isGenerating: (state: TStateSchema) => state.bgReplacement.isGenerating,
  hasMoreReplacements: (state: TStateSchema) =>
    state.bgReplacement.hasMoreReplacements,
  isGettingMoreReplacements: (state: TStateSchema) =>
    state.bgReplacement.isGettingMoreReplacements,

  bgReplacementType: (state: TStateSchema) =>
    state.bgReplacement.bgReplacementType,
  selectionBgTab: (state: TStateSchema) => state.bgReplacement.selectionBgTab,
  selectionBgLabel: (state: TStateSchema) =>
    state.bgReplacement.selectionBgLabel,
  aspectRatio: (state: TStateSchema) => state.bgReplacement.aspectRatio,
  imgSize: (state: TStateSchema) => state.bgReplacement.imgSize,
};
