import { createAsyncThunk } from "@reduxjs/toolkit";
import { TChat } from "../../slice/types";
import { chatBotApi, TUpdateChatData } from "../api/chatBotApi";
import { chatBotActions } from "../../slice";
// todo может стоит сразу изменять стейт не дожидаясь сервера
export const updateChat = createAsyncThunk(
  "chatBot/updateChat",
  async (
    {
      entity_id,
      updatedData,
      folder_id,
    }: {
      entity_id: TChat["id"];
      updatedData: TUpdateChatData;
      folder_id?: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(chatBotActions.isError(null));
      await chatBotApi.updateChat(entity_id, updatedData);

      return { chatId: entity_id, folder_id, updatedData };
    } catch (e) {
      dispatch(chatBotActions.isError(e));
      rejectWithValue(e);
    }
  }
);
