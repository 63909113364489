const IcGradientEye = () => (
  <svg
    width="36"
    height="26"
    viewBox="0 0 36 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.2 13.2C21.2 14.9673 19.7673 16.4 18 16.4C16.2327 16.4 14.8 14.9673 14.8 13.2C14.8 11.4327 16.2327 10 18 10C19.7673 10 21.2 11.4327 21.2 13.2Z"
      stroke="url(#paint0_linear_6097_134456)"
      strokeOpacity="0.72"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 13.2C29.7328 20.6672 24.4 24.4 18 24.4C11.6 24.4 6.2672 20.6672 2 13.2C6.2672 5.7328 11.6 2 18 2C24.4 2 29.7328 5.7328 34 13.2Z"
      stroke="url(#paint1_linear_6097_134456)"
      strokeOpacity="0.72"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6097_134456"
        x1="2"
        y1="1.68"
        x2="34"
        y2="24.08"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0084FF" />
        <stop offset="0.82" stopColor="#C974FE" />
        <stop offset="1" stopColor="#C974FE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6097_134456"
        x1="2"
        y1="1.68"
        x2="34"
        y2="24.08"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0084FF" />
        <stop offset="0.82" stopColor="#C974FE" />
        <stop offset="1" stopColor="#C974FE" />
      </linearGradient>
    </defs>
  </svg>
);

export default IcGradientEye;
