import s from "./Sidebar.module.css";
import { FC, memo, useState } from "react";
import { NavLink } from "react-router-dom";
import { RoutePath } from "../../../../shared/config/routeConfig/routeConfig";
import Logo from "../../../../shared/assets/icons/logo";
import { classNames } from "../../../../shared/lib/classNames/classNames";
import Button from "shared/ui/Button/Button";
import { BtnColor, BtnSize } from "../../../../shared/ui/Button/types";
import Badge from "../../../../shared/ui/Badge/Badge";
import { BadgeColor } from "../../../../shared/ui/Badge/types";
import LogoShort from "../../../../shared/assets/icons/logo-short";
import ChevronRight from "../../../../shared/assets/icons/chevron-right";
import ChevronLeft from "../../../../shared/assets/icons/chevron-left";
import UserAvatar, { AvatarSize } from "shared/ui/UserAvatar/UserAvatar";
import UserBalance from "shared/ui/UserBalance/UserBalance";
import Lightning from "shared/assets/icons/lightning";
import { useSelector } from "react-redux";
import { userSelector } from "entities/User/model/selectors";
import { MENU_ITEMS } from "widgets/Sidebar/constants";
import XClose from "shared/assets/icons/x-close";
import { subscriptionsSelector } from "entities/UserSubPlan";
import IcLock04 from "shared/assets/icons/IcLock-04";
import { TARIFFS_NAMES } from "pages/content/Tariffs/constants/plans";
import BgReplacementLimitsModal from "widgets/SearchBar/ui/BgReplacementLimitsModal/BgReplacementLimitsModal";

interface TSidebarProps {
  sidebarOpened: boolean;
  setSidebarOpened: (value: boolean) => void;
}

export const Sidebar: FC<TSidebarProps> = memo(
  ({ sidebarOpened, setSidebarOpened }) => {
    const [isBgLimits, setIsBgLimits] = useState(false);
    const [collapsed, setCollapsed] = useState(false);

    const userData = useSelector(userSelector.currentUser);
    const userPlan = useSelector(subscriptionsSelector.userSubPlan);

    const bgReplacementLimit =
      userPlan?.maxUserPlan.title === TARIFFS_NAMES.FREE ||
      userPlan?.maxUserPlan.title === TARIFFS_NAMES.START;

    return (
      <div
        className={classNames(s.sidebarContainer, {
          [s.sidebarOpen]: sidebarOpened,
        })}
        onClick={() => setSidebarOpened(false)}
      >
        <div
          className={classNames(s.sidebar, {
            [s.collapsed]: collapsed,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={s.sidebarHeader}>
            <NavLink
              to={RoutePath.MAIN}
              onClick={() => setSidebarOpened(false)}
            >
              {collapsed ? <LogoShort /> : <Logo />}
            </NavLink>
            <button
              onClick={() => setCollapsed(!collapsed)}
              className={s.collapseBtn}
            >
              {collapsed ? <ChevronRight /> : <ChevronLeft />}
            </button>
            <Button
              color={BtnColor.CLEAR}
              size={BtnSize.XXSMALL}
              className={s.mobileCloseBtn}
              onClick={() => setSidebarOpened(false)}
            >
              <XClose />
            </Button>
          </div>
          <div className={s.contentContainer}>
            <ul className={s.navList}>
              {MENU_ITEMS.map((item) => (
                <li key={item.path} className={s.navItem}>
                  <NavLink
                    onClick={(e) => {
                      if (item.disabled) {
                        e.preventDefault();
                      } else if (
                        item.path === RoutePath.BACKGROUND_REPLACEMENT &&
                        bgReplacementLimit
                      ) {
                        e.preventDefault();
                        setIsBgLimits(true);
                      } else {
                        setSidebarOpened(false);
                      }
                    }}
                    to={item.path}
                    className={({ isActive }) =>
                      classNames(s.navLink, {
                        [s.active]: isActive,
                        [s.disabled]: item.disabled,
                      })
                    }
                    end
                  >
                    {item.icon}
                    <span className={s.linkTitle}>{item.title}</span>
                    {item.disabled && <span className={s.soon}>Скоро</span>}
                    {item.path === RoutePath.BACKGROUND_REPLACEMENT &&
                      bgReplacementLimit && (
                        <div className="ml-auto">
                          <IcLock04 />
                        </div>
                      )}
                  </NavLink>
                </li>
              ))}
            </ul>
            <div className={s.profileContainer}>
              <div className={s.profile}>
                <div className={s.profileContent}>
                  <div className={s.avatarContainer}>
                    <UserAvatar size={AvatarSize.MEDIUM} />
                    <div className="flex flex-col gap-1.5">
                      <p className={s.title}>
                        {userData?.name} {userData?.surname}
                      </p>
                      <Badge color={BadgeColor.LIGHT_BLUE}>
                        {userPlan?.maxUserPlan.title} план
                      </Badge>
                    </div>
                  </div>
                  <UserBalance />
                </div>
                <Button
                  color={BtnColor.BLACK}
                  size={BtnSize.XXSMALL}
                  className={s.buttonPlane}
                  to={RoutePath.TARIFFS}
                  isFull
                >
                  <Lightning />
                  Выбрать план
                </Button>
              </div>
            </div>
          </div>
        </div>
        <BgReplacementLimitsModal
          isBgLimits={isBgLimits}
          setIsBgLimits={setIsBgLimits}
          userPlan={userPlan}
        />
      </div>
    );
  }
);
