import { createAsyncThunk } from "@reduxjs/toolkit";
import { userApi } from "../api/userApi";
import { userActions } from "../../slice";

export const getUserAvatar = createAsyncThunk(
  "user/getUserAvatar",
  async (_, { dispatch }) => {
    dispatch(userActions.isError(null));
    try {
      const avatar = await userApi.getAvatar();

      return { avatar };
    } catch (e: any) {
      dispatch(userActions.isError(e));
    } finally {
      dispatch(userActions.isLoading(false));
    }
  }
);
