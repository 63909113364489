import { configureStore } from "@reduxjs/toolkit";
import { TStateSchema } from "./types";
import { textDocumentsReducer } from "pages/content/Document/model/slice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { chatBotReducer } from "pages/content/ChatBot";
import { audiosReducer } from "pages/content/AudioTranscript";
import { userReducer } from "entities/User";

import { backgroundReplacementReducer } from "pages/content/BackgroundReplacement";
import { supportReducer } from "../../../../pages/content/Support/model/slice";
import { tariffsReducer } from "pages/content/Tariffs";
import { userSubPlanReducer } from "entities/UserSubPlan";
export const createReduxStore = (initialState?: TStateSchema) => {
  return configureStore<TStateSchema>({
    reducer: {
      textDocuments: textDocumentsReducer,
      chatBot: chatBotReducer,
      audios: audiosReducer,
      user: userReducer,
      bgReplacement: backgroundReplacementReducer,
      support: supportReducer,
      tariffs: tariffsReducer,
      userSubPlan: userSubPlanReducer,
    },
    devTools: true,
    preloadedState: initialState,
  });
};
const store = createReduxStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<TStateSchema> = useSelector;
