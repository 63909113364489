import audiosApi from "../api/audiosApi";
import { audiosActions } from "./../../slice/index";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const deleteAudio = createAsyncThunk(
  "audios/deleteAudio",
  async (arg: { id: string }, { rejectWithValue, dispatch }) => {
    dispatch(audiosActions.isError(null));

    try {
      audiosApi.deleteAudio(arg.id);
      return { id: arg.id };
    } catch (e) {
      dispatch(audiosActions.isError(e));
      rejectWithValue(e);
    }
  }
);
