import React from "react";
import { ModalOverlay } from "shared/ui/ModalOverlay/ModalOverlay";
import s from "./LimitsModal.module.css";
import Button from "shared/ui/Button/Button";
import { BtnColor } from "shared/ui/Button/types";
import { RoutePath } from "shared/config/routeConfig/routeConfig";
import XClose from "shared/assets/icons/x-close";
import { useSelector } from "react-redux";
import { subscriptionsSelector } from "entities/UserSubPlan";
const LimitsModal = ({
  open,
  onClose,
  children,
  title,
  description,
  contentText,
}: {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  title: string | JSX.Element;
  description?: string | JSX.Element;
  contentText?: string | JSX.Element;
}) => {
  const userPlan = useSelector(subscriptionsSelector.userSubPlan);
  return (
    <ModalOverlay open={open} onClose={onClose}>
      <div className={s.container}>
        <div className={s.info}>
          <div className="flex justify-between gap-3 items-start">
            <h2>{title}</h2>
            <button onClick={onClose}>
              <XClose />
            </button>
          </div>
          <div className={s.description}>{description}</div>
        </div>
        <div className={s.content}>
          {contentText ? (
            <div className="flex flex-col gap-2">
              <p className="text-[18px] font-medium">{contentText}</p>
              <p>
                <span className="text-gray-400">Текущий тариф</span>
                <br />
                {userPlan?.maxUserPlan.title}
              </p>
            </div>
          ) : (
            children
          )}
          <div className={s.btnsContainer}>
            <Button color={BtnColor.WHITE} onClick={onClose} isFull>
              Позже
            </Button>
            <Button color={BtnColor.GRADIENT} to={RoutePath.TARIFFS} isFull>
              Обновить тариф
            </Button>
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
};

export default LimitsModal;
