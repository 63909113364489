import { ASPECT_RATIOS, IMAGE_SIZES } from "./../../constants/constants";
import { BACKGROUND_TYPES } from "../../constants/constants";

export type TReplacement = {
  id: string;
  image_b64: string;
  media_type: string;
  tokens_usage: number;
  created_at: string;
  size: TImageSize;
};
export enum BgType {
  GENERATION = "generation",
  SELECTION = "selection",
}
export type TBackgroundLabel = Extract<
  {
    [K in keyof typeof BACKGROUND_TYPES]: (typeof BACKGROUND_TYPES)[K]["backgrounds"][number]["label"];
  }[keyof typeof BACKGROUND_TYPES],
  string
>;
export type TImageSize = (typeof IMAGE_SIZES)[keyof typeof IMAGE_SIZES][number];

export interface TBackgroundReplacementSchema {
  isLoading: boolean;
  isError: boolean | string | null;
  currentImage: string | null;
  allReplacements: TReplacement[] | null;
  offset: number;
  isGenerating: boolean;
  isGettingMoreReplacements: boolean;
  hasMoreReplacements: boolean;

  bgReplacementType: BgType;
  selectionBgTab: keyof typeof BACKGROUND_TYPES;
  selectionBgLabel: TBackgroundLabel | "none";
  aspectRatio: (typeof ASPECT_RATIOS)[number];
  imgSize: TImageSize;
}
