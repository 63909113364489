import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatBotActions } from "../../slice";
import { chatBotApi } from "../api/chatBotApi";
import { chatBotThunks } from "..";

export const getAllChats = createAsyncThunk(
  "chatBot/getAllChats",
  async (
    { isCurrentChat }: { isCurrentChat: boolean },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(chatBotActions.isError(null));
      dispatch(chatBotActions.isLoading(true));
      dispatch(chatBotThunks.getGptModels());

      const allChatsResponse = await chatBotApi.getAllChats();
      if (isCurrentChat) {
        return { allChats: allChatsResponse };
      }
      // Проверяем, есть ли чаты
      if (allChatsResponse.ai_chats && allChatsResponse.ai_chats.length > 0) {
        // Получаем историю сообщений первого чата
        const messagesStoryResponse = await chatBotApi.getMessagesStory(
          allChatsResponse.ai_chats[0].id,
          0
        );

        // Если история сообщений первого чата пустая, то не создаем новый чат
        if (messagesStoryResponse.messages_story.length === 1) {
          return { allChats: allChatsResponse };
        }
      }

      // Создаем новый чат, если чатов нет или история сообщений первого чата не пустая
      const newChatResponse = await chatBotApi.createChat();

      return {
        allChats: allChatsResponse,
        newChat: newChatResponse,
      };
    } catch (error) {
      rejectWithValue(error);
      dispatch(chatBotActions.isError(error));
    } finally {
      dispatch(chatBotActions.isLoading(false));
    }
  }
);
