import { TStateSchema } from "app/providers/StoreProvider/config/types";

export const chatBotSelector = {
  getAllChats: (state: TStateSchema) => state.chatBot.allChats,
  getCurrentChat: (state: TStateSchema) => state.chatBot.currentChat,
  getIsLoading: (state: TStateSchema) => state.chatBot.isLoading,
  getIsError: (state: TStateSchema) => state.chatBot.isError,
  getAllChatsFolders: (state: TStateSchema) => state.chatBot.allChats.folders,
  getGptModels: (state: TStateSchema) => state.chatBot.gptModels,
};
