import { createAsyncThunk } from "@reduxjs/toolkit";
import documentsApi, { TUpdateDocumentData } from "../api/documentsApi";
import { TDocument } from "../../slice/types";
import { textDocumentsActions } from "../../slice";

export const updateDocument = createAsyncThunk(
  "textDocuments/updateDocument",
  async (
    {
      entity_id: doc_id,
      updatedData,
    }: { entity_id: string; updatedData: TUpdateDocumentData },
    { rejectWithValue, dispatch }
  ) => {
    // dispatch(textDocumentsActions.isLoading(true));
    try {
      dispatch(textDocumentsActions.isError(null));
      const res: TDocument = await documentsApi.updateDocument(
        doc_id,
        updatedData
      );

      return { updateDocument: res };
    } catch (e) {
      dispatch(textDocumentsActions.isError(e));
      rejectWithValue(e);
    } finally {
      // dispatch(textDocumentsActions.isLoading(false));
    }
  }
);
