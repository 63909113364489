import { getAllChats } from "./../../../ChatBot/model/services/thunks/getAllChats";
import useHttp from "shared/api/services/api";
import { API_PREFIX } from "shared/api/constants/api";
import { NewRequestType } from "../types/apiTypes";

export default class SupportApi {
  static http = useHttp(API_PREFIX.SUPPORT);

  static async streamMessages(chatId: string) {
    const url = `/${chatId}/messages/stream`;
    const eventSource = new EventSource(SupportApi.http.defaults.baseURL + url);

    return eventSource;
  }

  static async createNewMessageByAdmin(arg: {
    chatId: string;
    text: string;
    attachments: File[];
  }) {
    const formData = new FormData();
    formData.append("text", arg.text);
    arg.attachments.forEach((file, index) => {
      formData.append(`attachments[${index}]`, file);
    });

    const { data } = await SupportApi.http.post(
      `admin/${arg.chatId}/send_message`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  }

  static async createNewMessageByUser(arg: {
    chatId: string;
    text: string;
    attachments: File[];
  }) {
    const formData = new FormData();
    formData.append("text", arg.text);
    arg.attachments.forEach((file, index) => {
      formData.append(`attachments[${index}]`, file);
    });

    const { data } = await SupportApi.http.post(
      `me/${arg.chatId}/send_message`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  }

  static async createRequest(arg: NewRequestType) {
    const { data } = await SupportApi.http.post("", {
      topic: arg.topic,
      category: arg.category,
      urgency: arg.urgency,
      text: arg.text,
    });
    return data;
  }

  static async getAllRequests() {
    const { data } = await SupportApi.http.get("me", {
      params: {
        limit: 25,
      },
    });
    return data;
  }

  static async getAllRequestsForAdmin() {
    const { data } = await SupportApi.http.get("admin", {
      params: {
        limit: 25,
      },
    });
    return data;
  }

  static async getChatsMessagesAdmin(arg: {
    chatId: string;
    offset: number;
    limit: number;
  }) {
    const { data } = await SupportApi.http.get(`admin/${arg.chatId}/messages`, {
      params: {
        offset: arg.offset,
        limit: arg.limit,
      },
    });
    return data;
  }

  static async getChatsMessagesUser(arg: {
    chatId: string;
    offset: number;
    limit: number;
  }) {
    const { data } = await SupportApi.http.get(`me/${arg.chatId}/messages`, {
      params: {
        offset: arg.offset,
        limit: arg.limit,
      },
    });
    return data;
  }

  static async getCurrentRequest(arg: { chatId: string }) {
    const { data } = await SupportApi.http.get(`${arg.chatId}`, {});
    return data;
  }
  static async getSendRequestInArchive(arg: { chatId: string }) {
    const { data } = await SupportApi.http.post(
      `${arg.chatId}/mark_as_resolved`,
      {}
    );
    return data;
  }
  static async getSendRequestInDelete(arg: { chatId: string }) {
    const { data } = await SupportApi.http.delete(`${arg.chatId}`, {});
    return data;
  }
}
