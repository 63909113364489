import React, { useEffect, useRef, useState } from "react";
import styles from "./HelperChat.module.scss";
import MessagesList from "./MessagesList/MessagesList";
import { SendMessage } from "./SendMessage/SendMessage";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../app/providers/StoreProvider/config/store";
import { supportThunks } from "../../../model/slice/thunks";
import { userSelector } from "../../../../../../entities/User/model/selectors";
import { supportActions } from "../../../model/slice";
import { useStreamMessages } from "pages/content/Support/model/hook/useStreamMessages";

type HelperChatProps = {
  chatId: string | undefined;
  status: string | null;
};

export const HelperChat: React.FC<HelperChatProps> = ({ chatId, status }) => {
  const dispatch = useAppDispatch();
  const isAdmin =
    useAppSelector(userSelector.currentUser)?.is_superuser || false;
  const loading = useAppSelector((state) => state.support.loading);
  const messages = useAppSelector((state) => state.support.messages);
  const hasMoreUp = useAppSelector((state) => state.support.hasMoreUp);
  const offset = useAppSelector((state) => state.support.offset);
  const limit = useAppSelector((state) => state.support.limit);

  const [messageValue, setMessageValue] = useState<string>("");
  const messagesListRef = useRef<HTMLDivElement>(null);
  const streaming = useAppSelector((state) => state.support.streaming);

  useStreamMessages(chatId);

  useEffect(() => {
    dispatch(supportActions.setIsAdmin(isAdmin));
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (chatId) {
      // Сбрасываем состояние чата при смене chatId
      dispatch(supportActions.resetChatState());

      // Загружаем последние сообщения с offset = 0
      dispatch(
        supportThunks.loadChatMessagesAT({
          chatId,
          offset: 0,
          limit,
          direction: "down", // Загружаем последние сообщения
          isAdmin,
        })
      );
    }
  }, [dispatch, chatId, isAdmin, limit]);

  const handleScroll = () => {
    if (!messagesListRef.current || !chatId || loading) return;

    const { scrollTop } = messagesListRef.current;

    const threshold = 0;

    if (scrollTop <= threshold && hasMoreUp && !loading) {
      const previousScrollHeight = messagesListRef.current.scrollHeight;

      dispatch(
        supportThunks.loadChatMessagesAT({
          chatId,
          offset,
          limit,
          direction: "up",
          isAdmin,
        })
      )
        .unwrap()
        .then((response) => {
          if (messagesListRef.current) {
            const newScrollHeight = messagesListRef.current.scrollHeight;
            messagesListRef.current.scrollTop =
              newScrollHeight - previousScrollHeight + scrollTop;
          }
        })
        .catch((error) => {
          console.error("Ошибка при загрузке сообщений:", error);
          dispatch(supportActions.setHasMoreUp(false));
        });
    }
  };

  const handleSendMessage = () => {
    if (messageValue.trim() === "" || !chatId) return;

    if (isAdmin) {
      dispatch(
        supportThunks.createNewMessageByAdmin({
          chatId,
          text: messageValue,
          attachments: [],
        })
      );
    } else {
      dispatch(
        supportThunks.createNewMessageByUserAT({
          chatId,
          text: messageValue,
          attachments: [],
        })
      );
    }

    setMessageValue("");
  };

  useEffect(() => {
    function setFullHeight() {
      const viewportHeight = window.innerHeight;
      const el = document.querySelector("#chat-container");
      if (el) {
        // @ts-ignore
        el.style.height = `${viewportHeight - 70}px`;
      }
    }
    setFullHeight();
    window.addEventListener("resize", setFullHeight);
    window.addEventListener("load", setFullHeight);
    return () => {
      window.removeEventListener("resize", setFullHeight);
      window.removeEventListener("load", setFullHeight);
    };
  }, []);

  return (
    <div className={styles.container} id="chat-container">
      <div
        className={styles.messagesList}
        onScroll={handleScroll}
        ref={messagesListRef}
      >
        {loading && <span>Загрузка...</span>}
        {/*{streaming && <span>Подключение к потоку...</span>}*/}
        <MessagesList messages={messages} />
      </div>
      <div className={styles.sendMessage}>
        <SendMessage
          status={status}
          value={messageValue}
          onChange={(e) => setMessageValue(e.target.value)}
          onSend={handleSendMessage}
        />
      </div>
    </div>
  );
};
