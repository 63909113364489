import { createAsyncThunk } from "@reduxjs/toolkit";
import { RequestType } from "../../types/supportDataTypes";
import SupportApi from "../../api/supportApi";
import { NewRequestType } from "../../types/apiTypes";

export const createNewRequestAT = createAsyncThunk<
  { requests: RequestType[] },
  {
    model: NewRequestType;
  }
>("support/getNewRequest", async (arg, thunkApi) => {
  const { rejectWithValue } = thunkApi;
  // debugger;

  try {
    const res = await SupportApi.createRequest({
      text: arg.model.text,
      urgency: arg.model.urgency,
      topic: arg.model.topic,
      category: arg.model.category,
    });
    return { requests: res };
  } catch (e) {
    return rejectWithValue(e);
  }
});
