import { ISupportSliceType } from "../index";

export const supportSelectors = {
  allRequests: (state: { support: ISupportSliceType }) =>
    state.support.requests,
  allRequestsArchive: (state: { support: ISupportSliceType }) => {
    return state.support.requestsArchive;
  },
  supportLoading: (state: { support: ISupportSliceType }) =>
    state.support.supportLoading,
  getCurrentRequest: (state: { support: ISupportSliceType }) =>
    state.support.currentRequest,
};
