import React, { memo } from "react";
import Notification, { NotificationType } from "../Notification/Notification";
import s from "./NotificationsGroup.module.css";
export interface TNotificationsGroup {
  notifications: { id: number; text: string; type?: NotificationType }[] | [];
  setNotifications: () => void;
  type?: NotificationType;
}
// todo ts and maybe should add COMMON_ERROR_TEXT here
const NotificationsGroup = memo(
  ({ notifications, setNotifications, type }: TNotificationsGroup) => {
    const removeNotification = (id: number) => {
      // @ts-ignore
      setNotifications((prev) =>
        prev.filter((notification: any) => notification.id !== id)
      );
    };
    return (
      <div className={s.container}>
        {notifications.map((notification, i) => (
          <Notification
            key={notification.id + i}
            text={notification.text}
            type={type ?? notification.type}
            onHide={() => removeNotification(notification.id)}
          />
        ))}
      </div>
    );
  }
);
export default NotificationsGroup;
