import { Provider } from "react-redux";
import { createReduxStore } from "../config/store";
import { FC, PropsWithChildren } from "react";
import { TStateSchema } from "../config/types";

interface IStoreProviderProps {
  initialState?: TStateSchema;
}

export const StoreProvider: FC<PropsWithChildren<IStoreProviderProps>> = ({
  children,
  initialState,
}) => {
  const store = createReduxStore(initialState);
  return <Provider store={store}>{children}</Provider>;
};
