import { createAsyncThunk } from "@reduxjs/toolkit";
import { backgroundReplacementActions } from "../../slice";
import bgReplacementApi from "../api/backgroundReplacementApi";
import { userSubPlanActions } from "entities/UserSubPlan";

export const removeBackground = createAsyncThunk(
  "bgReplacement/removeBackground",
  async (arg: { image: File }, { dispatch }) => {
    dispatch(backgroundReplacementActions.isLoading(true));
    dispatch(backgroundReplacementActions.isError(null));
    try {
      const replacement = await bgReplacementApi.removeBackground(arg.image);
      if (replacement.premium_tokens_usage) {
        dispatch(
          userSubPlanActions.changePremiumTokensCount({
            used_tokens: replacement.premium_tokens_usage,
          })
        );
      }
      // console.log(replacement);
      return { replacement: replacement.image };
    } catch (e) {
      dispatch(backgroundReplacementActions.isError(e));
    } finally {
      dispatch(backgroundReplacementActions.isLoading(false));
    }
  }
);
