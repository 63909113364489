import { createAsyncThunk } from "@reduxjs/toolkit";
import documentsApi from "../api/documentsApi";
import { textDocumentsActions } from "../../slice";
import { TDocument } from "../../slice/types";

export const duplicateDocument = createAsyncThunk(
  "textDocuments/duplicateDocument",
  async ({ doc_id }: { doc_id: string }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(textDocumentsActions.isError(null));
      dispatch(textDocumentsActions.isLoading(true));

      const data = await documentsApi.duplicateDocument(doc_id);

      return { newDoc: data };
    } catch (e: any) {
      dispatch(textDocumentsActions.isError(e));
      return rejectWithValue(e.message);
    } finally {
      dispatch(textDocumentsActions.isLoading(false));
    }
  }
);
