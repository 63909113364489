import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { LS_ACCESS_TOKEN } from "shared/api/constants/localStorage";
import { RoutePath } from "shared/config/routeConfig/routeConfig";
import Preloader from "shared/ui/Preloader/Preloader";

export const AuthLayout = () => {
  const navigate = useNavigate();
  const [preloader, setPreloader] = useState(true);

  useEffect(() => {
    if (localStorage.getItem(LS_ACCESS_TOKEN)) {
      navigate(RoutePath.MAIN);
    } else {
      setPreloader(false);
    }
  }, []);

  if (preloader) {
    return <Preloader />;
  }
  return <Outlet />;
};
