import { Routes } from "react-router-dom";
import Preloader from "../../../../../shared/ui/Preloader/Preloader";
import React, { Suspense } from "react";
import getRoutes from "../../lib/getRoutes/getRoutes";
import { CHILDREN_ROUTES } from "../../constants/routes";

export const AppRouter = () => {
  return (
    // todo
    <Suspense fallback={<Preloader />}>
      <Routes>
        {Object.values(CHILDREN_ROUTES).map((routes, i) => (
          <React.Fragment key={i}>{getRoutes({ ...routes })}</React.Fragment>
        ))}
      </Routes>
    </Suspense>
  );
};
