import { useEffect, useState } from "react";
import { COMMON_ERROR_TEXT } from "shared/api/constants/errors";
import { createErrorNotification } from "shared/lib/createErrorNotification/createErrorNotification";
import { NotificationType } from "shared/ui/Notification/Notification";
import NotificationsGroup, {
  TNotificationsGroup,
} from "shared/ui/NotificationsGroup/NotificationsGroup";

type TError = keyof typeof COMMON_ERROR_TEXT | false | null;
// todo instead of it u can usr rtk query error loading states
const useFetch = (
  request: any,
  requestName: string,
  onError?: any,
  initialData?: any,
  onSuccessMessage?: string
) => {
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<TError>(null);
  const [notifications, setNotifications] = useState<
    TNotificationsGroup["notifications"]
  >([]);

  useEffect(() => {
    if (notifications.length > 2) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [notifications.length]);
  const fetchData = async (...props: any) => {
    setLoading(true);
    setError(null);

    try {
      const response = await request(...props);
      setData(response);
      setNotifications((prev: any) => [
        ...prev,
        {
          text: onSuccessMessage ?? "Запрос успешно выполнен",
          type: "success",
        },
      ]);
    } catch (err: any) {
      setError(
        err.response && !Array.isArray(err.response?.data.detail)
          ? err.response?.data.detail
          : (err.code ?? err.message)
      );
      onError && onError(err);
      createErrorNotification(err, setNotifications);
    } finally {
      setLoading(false);
    }
  };
  const notificationsStack =
    notifications.length > 0 ? (
      <NotificationsGroup
        notifications={notifications}
        // @ts-expect-error
        setNotifications={setNotifications}
        // type={NotificationType.Error}
      />
    ) : null;

  return {
    data,
    loading,
    error,
    fetchData,
    setError,
    notifications: notificationsStack,
  };
};
export default useFetch;
