import { NavLink, NavLinkProps } from "react-router-dom";
import s from "./AppLink.module.css";
import { classNames } from "shared/lib/classNames/classNames";

export enum AppLinkTheme {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  BRAND = "brand",
  INVERTED_PRIMARY = "inverted_primary",
}
export enum AppLinkSize {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
  XSMALL = "xsmall",
}
interface AppLinkProps extends NavLinkProps {
  className?: string;
  theme?: AppLinkTheme;
  border?: boolean;
  size?: AppLinkSize;
  onClick?: () => void;
}
export const AppLink = ({
  to,
  className = "",
  children,
  theme = AppLinkTheme.PRIMARY,
  border,
  size = AppLinkSize.MEDIUM,
  onClick,
}: AppLinkProps) => {
  return (
    <NavLink
      to={to}
      className={classNames(s.link, { [s.border]: border }, [
        className,
        s[theme],
        s[size],
      ])}
      onClick={onClick}
    >
      {children}
    </NavLink>
  );
};
