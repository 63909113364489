const Command = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 7H8V5H9C10.103 5 11 4.10279 11 3C11 1.89722 10.103 1 9 1C7.89698 1 7 1.89722 7 3V4H5V3C5 1.89722 4.10302 1 3 1C1.89698 1 1 1.89722 1 3C1 4.10279 1.89698 5 3 5H4V7H3C1.89698 7 1 7.89721 1 9C1 10.1028 1.89698 11 3 11C4.10302 11 5 10.1028 5 9V8H7V9C7 10.1028 7.89698 11 9 11C10.103 11 11 10.1028 11 9C11 7.89721 10.103 7 9 7ZM8 3C8 2.44849 8.44873 2 9 2C9.55127 2 10 2.44849 10 3C10 3.55152 9.55127 4 9 4H8V3ZM4 9C4 9.55151 3.55127 10 3 10C2.44873 10 2 9.55151 2 9C2 8.44848 2.44873 8 3 8H4V9ZM4 4H3C2.44873 4 2 3.55152 2 3C2 2.44849 2.44873 2 3 2C3.55127 2 4 2.44849 4 3V4ZM7 7H5V5H7V7ZM9 10C8.44873 10 8 9.55151 8 9V8H9C9.55127 8 10 8.44848 10 9C10 9.55151 9.55127 10 9 10Z"
      fill="#667085"
    />
  </svg>
);

export default Command;
