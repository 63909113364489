import AuthApi from "features/Auth/services/AuthApi";
import { AuthWrapper } from "layouts/AuthWrapper";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RoutePath } from "shared/config/routeConfig/routeConfig";
import { PAGE_TITLES } from "shared/constants/common";
import useFetch from "shared/hooks/useFetch";
import Button from "shared/ui/Button/Button";
import { BtnColor, BtnSize } from "shared/ui/Button/types";

const ErrorMessage = () => {
  useEffect(() => {
    document.title = PAGE_TITLES.FORGOT_PASSWORD;
  }, []);
  const [noEmail, setNoEmail] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const resetPassword = async () => {
    if (!state?.email) {
      setNoEmail(true);
      return;
    }
    await AuthApi.sendResetLink(state.email);
    navigate(RoutePath.RECOVERY_MESSAGE);
  };
  const { fetchData, loading, notifications } = useFetch(
    resetPassword,
    "reset password email"
  );
  return (
    <AuthWrapper
      title="Мы не можем обработать запрос в данный момент"
      supportLink
      description={"Пожалуйста, попробуй позже."}
    >
      {noEmail ? (
        <p className=" text-red-500 text-center w-full">
          Пожалуйста, вернись на страницу{" "}
          <NavLink
            to={RoutePath.RESET_PASSWORD}
            className=" border-b border-1 border-red-500"
          >
            сброса пароля
          </NavLink>{" "}
          и введи почту
        </p>
      ) : (
        <Button
          color={BtnColor.WHITE}
          isFull
          size={BtnSize.LARGE}
          isLoading={loading}
          onClick={fetchData}
        >
          Попробовать снова
        </Button>
      )}
    </AuthWrapper>
  );
};

export default ErrorMessage;
