const AvatarLightL = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#98A2B3" />
    <path
      opacity="0.6"
      d="M33.2666 21.1628C31.1094 12.5116 27.0312 10 20 10C12.9688 10 8.93563 12.5116 6.73344 21.1628C4.53125 29.814 2 40 2 40H9.59375C9.59375 40 13.7169 23.6744 14.3272 21.1628C14.9375 18.6512 16.4844 12.5116 20 12.5116C23.5156 12.5116 25.0175 18.6512 25.6728 21.1628C26.3281 23.6744 30.4062 40 30.4062 40H38C38 40 35.4237 29.814 33.2666 21.1628Z"
      fill="white"
    />
  </svg>
);

export default AvatarLightL;
