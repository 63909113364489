import { deleteAudio } from "./deleteAudio";
import { getAllAudios } from "./getAllAudios";
import { getAudioTranscript } from "./getAudioTranscript";
import { getCurrentAudio } from "./getCurrentAudio";
import { getSearchedTranscript } from "./getSearchedTranscript";
import { retryCurrentAudio } from "./retryCurrentAudio";

export const audiosThunks = {
  getAllAudios,
  getAudioTranscript,
  deleteAudio,
  retryCurrentAudio,
  getSearchedTranscript,
};
