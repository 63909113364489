import audiosApi from "../api/audiosApi";
import { audiosActions } from "./../../slice/index";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { userSubPlanActions } from "entities/UserSubPlan";

export const getAudioTranscript = createAsyncThunk(
  "audios/getAudioTranscript",
  async (arg: { voice: File }, { rejectWithValue, dispatch }) => {
    dispatch(audiosActions.setCurrentAudio({ id: "transcripting" }));
    dispatch(audiosActions.setIsTranscripting(true));
    dispatch(audiosActions.isError(null));

    try {
      const res = await audiosApi.voiceToText(arg.voice);
      if (res.tokens_usage) {
        dispatch(
          userSubPlanActions.changeTokensCount({
            used_tokens: res.tokens_usage,
          })
        );
      }
      return { audio: res.audio };
    } catch (e) {
      dispatch(audiosActions.isError(e));
      rejectWithValue(e);
    } finally {
      dispatch(audiosActions.setIsTranscripting(false));
    }
  }
);
