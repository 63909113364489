import audiosApi from "../api/audiosApi";
import { audiosActions } from "./../../slice/index";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAllAudios = createAsyncThunk(
  "audios/getAllAudios",
  async (arg, { rejectWithValue, dispatch }) => {
    dispatch(audiosActions.isLoading(true));
    dispatch(audiosActions.isError(null));

    try {
      const res = await audiosApi.getAllAudios();
      return { audios: res };
    } catch (e) {
      dispatch(audiosActions.isError(e));
      rejectWithValue(e);
    } finally {
      dispatch(audiosActions.isLoading(false));
    }
  }
);
