import { memo } from "react";
import s from "./AuthFooter.module.css";
import { AppLink, AppLinkTheme } from "shared/ui/AppLink/AppLink";
import { RoutePath } from "shared/config/routeConfig/routeConfig";

const AuthFooter = memo(
  ({
    description,
    loginLink,
  }: {
    description?: string | JSX.Element;
    loginLink?: boolean;
  }) => {
    return (
      <footer className={s.footer}>
        <div className={s.p}>
          {loginLink ? (
            <p className="flex gap-2 text-base">
              <span>Уже есть аккаунт?</span>
              <AppLink to={RoutePath.LOGIN} theme={AppLinkTheme.BRAND}>
                Войти
              </AppLink>
            </p>
          ) : (
            (description ?? (
              <AppLink to={RoutePath.LOGIN} theme={AppLinkTheme.BRAND}>
                Вернуться ко входу
              </AppLink>
            ))
          )}
        </div>
      </footer>
    );
  }
);

export default AuthFooter;
