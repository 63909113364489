import { Tooltip } from "@mui/material";
import React from "react";

const MuiCustomTooltip = ({
  title,
  children,
  placement = "bottom-start",
}: {
  title: string | JSX.Element;
  children: JSX.Element;
  placement?:
    | "bottom"
    | "bottom-start"
    | "bottom-end"
    | "top"
    | "top-start"
    | "top-end"
    | "right"
    | "right-start"
    | "right-end"
    | "left"
    | "left-start"
    | "left-end";
}) => {
  const tooltipProps = {
    componentsProps: {
      tooltip: {
        sx: {
          padding: "12px",
          bgcolor: "var(--bg-inverted-primary-color)",
          color: "var(--text-inverted-primary-color)",
          fontSize: "14px",
          lineHeight: "21px",
          "& .MuiTooltip-arrow": {
            color: "var(--bg-inverted-primary-color)",
          },
        },
      },
    },

    color: "black",
    placement,
    arrow: true,
  } as const;
  return (
    <Tooltip {...tooltipProps} title={title}>
      {children}
    </Tooltip>
  );
};

export default MuiCustomTooltip;
