import { createSlice } from "@reduxjs/toolkit";
import { TChatBotSchema } from "./types";
import { chatBotThunks } from "../services";

const initialState: TChatBotSchema = {
  allChats: {
    ai_chats: null,
    folders: null,
  },
  currentChat: {
    id: "",
    name: "",
    messages_story: [],
  },
  isLoading: false,
  gptModels: {},
  isError: false,
};

const textDocumentsSlice = createSlice({
  name: "chatBot",
  initialState,
  reducers: {
    isLoading(state, { payload }) {
      state.isLoading = payload;
    },
    isError(state, { payload }) {
      state.isError = payload;
    },
    updateCurrentChatHistory(state, { payload }) {
      // console.log("payload", payload);
      if (state.currentChat) {
        state.currentChat.messages_story = [
          ...state.currentChat.messages_story,
          payload,
        ];
      }
    },
    retryRequest(state) {
      if (state.currentChat) {
        state.currentChat.messages_story.pop();
      }
    },
    resetCurrentChat(state) {
      state.currentChat = {
        id: "",
        name: "",
        messages_story: [],
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(chatBotThunks.getAllChats.fulfilled, (state, { payload }) => {
        if (payload) {
          state.allChats = payload.allChats;
          // todo что то странное, надо отдельный поинт на добавление нового чата....
          if (payload?.newChat) {
            state.allChats.ai_chats = [
              payload.newChat,
              ...(state.allChats.ai_chats || []),
            ];

            state.currentChat = payload.newChat;
          }
        }
      })
      .addCase(chatBotThunks.getCurrentChat.fulfilled, (state, { payload }) => {
        state.currentChat = payload.currentChat;
      })
      .addCase(chatBotThunks.getGptModels.fulfilled, (state, { payload }) => {
        if (payload) {
          state.gptModels = payload?.gptModels;
        }
      })

      .addCase(
        chatBotThunks.getChatMessages.fulfilled,
        (state, { payload }) => {
          if (payload) {
            state.currentChat.messages_story = [
              ...payload.currentChatMessages,
              ...(state.currentChat.messages_story || []),
            ];
          }
        }
      )

      .addCase(chatBotThunks.createNewChat.fulfilled, (state, { payload }) => {
        state.currentChat = payload.chat;

        state.allChats.ai_chats = [
          payload.chat,
          ...(state.allChats.ai_chats || []),
        ];
      })
      .addCase(chatBotThunks.deleteChat.fulfilled, (state, { payload }) => {
        const chatId = payload?.chat_id;
        const filterCallback = (chat: any) => chat.id !== chatId;

        const folderId = payload?.folder_id;

        if (folderId) {
          const folder = state.allChats.folders?.find(
            (folder) => folder.id === folderId
          );

          if (folder) {
            folder.ai_chats = folder.ai_chats.filter(filterCallback);
          }
        } else {
          state.allChats.ai_chats =
            state.allChats.ai_chats?.filter(filterCallback) ?? [];
        }

        // if (state.currentChat?.id === chatId) {
        //   state.currentChat = state.allChats.ai_chats?.[0] || {
        //     id: "",
        //     name: "",
        //     messages_story: [],
        //   };
        // }
      })

      .addCase(
        chatBotThunks.deleteFolderWithChats.fulfilled,
        (state, { payload }) => {
          const deletedFolder = state.allChats.folders?.find(
            (folder) => folder.id === payload?.deletedFolderId
          );

          if (
            deletedFolder?.ai_chats?.find(
              (doc) => doc.id === state?.currentChat?.id
            )
          ) {
            state.currentChat = {
              id: "",
              name: "",
              messages_story: [],
            };
          }
          state.allChats.folders =
            state.allChats.folders?.filter(
              (folder) => folder.id !== payload?.deletedFolderId
            ) || [];
        }
      )
      .addCase(chatBotThunks.updateChat.fulfilled, (state, { payload }) => {
        const folderId = payload?.folder_id;
        const chatId = payload?.chatId;
        const newData = payload?.updatedData;

        let chat;

        if (payload)
          if (folderId) {
            const folder = state.allChats.folders?.find(
              (chat) => chat.id === folderId
            );

            if (folder) {
              chat = folder.ai_chats.find((chat) => chat.id === chatId);
            }
          } else {
            const existedChat = state.allChats.ai_chats?.find(
              (chat) => chat.id === chatId
            );
            if (existedChat) {
              chat = existedChat;
            }
          }
        if (chat) {
          if (newData?.name) {
            chat.name = newData.name;

            if (state.currentChat && state.currentChat?.id === chatId) {
              state.currentChat.name = newData.name;
            }
          }
          if (newData?.model) {
            chat.model = newData.model;
            if (state.currentChat && state.currentChat?.id === chatId) {
              state.currentChat.model = newData.model;
            }
          }
        }
      })
      .addCase(chatBotThunks.updateFolder.fulfilled, (state, { payload }) => {
        const folderId = payload?.updatedFolder.id;

        const folder = state.allChats.folders?.find(
          (folder) => folder.id === folderId
        );

        if (folder) {
          folder.name = payload?.updatedFolder.name;
        }
      })

      .addCase(
        chatBotThunks.deleteChatFromFolder.fulfilled,
        (state, { payload }) => {
          const chatId = payload?.chat_id;
          const folderId = payload?.folder_id;

          const folder = state.allChats.folders?.find(
            (folder) => folder.id === folderId
          );
          const chat = folder?.ai_chats.find((chat) => chat.id === chatId);

          if (folder && chat) {
            folder.ai_chats = folder.ai_chats?.filter(
              (chat) => chat.id !== chatId
            );
            //@ts-ignore
            state.allChats.ai_chats?.unshift(chat);
          }
        }
      )

      .addCase(
        chatBotThunks.createNewFolder.fulfilled,
        (state, { payload }) => {
          state.allChats.folders?.push(payload?.folder);
        }
      )

      .addCase(
        chatBotThunks.addChatToFolder.fulfilled,
        (state, { payload }) => {
          const folderId = payload?.folder_id;
          const chatId = payload?.chatId;

          const chat = state.allChats.ai_chats?.find(
            (chat) => chat.id === chatId
          );
          const folder = state.allChats.folders?.find(
            (folder) => folder.id === folderId
          );

          if (folder && chat) {
            folder.ai_chats = [chat, ...folder.ai_chats];
          }

          state.allChats.ai_chats =
            state.allChats.ai_chats?.filter((chat) => chat.id !== chatId) || [];
        }
      );
  },
});

export const { reducer: chatBotReducer } = textDocumentsSlice;
export const { actions: chatBotActions } = textDocumentsSlice;
