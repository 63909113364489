import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestsType, ServerMessage } from "../types/supportDataTypes";
import { supportThunks } from "./thunks";

export interface ISupportSliceType {
  requests: null | RequestsType[];
  requestsArchive: null | RequestsType[];
  currentRequest: null | RequestsType;
  supportLoading: boolean;

  messages: ServerMessage[];
  offset: number;
  limit: number;
  hasMoreUp: boolean;
  loading: boolean;
  isAdmin: boolean;
  streaming: boolean;
}

const initialState: ISupportSliceType = {
  requests: null,
  requestsArchive: null,
  currentRequest: null,
  supportLoading: false,
  loading: false,
  messages: [],
  offset: 0,
  limit: 25,
  hasMoreUp: true,
  streaming: false,
  isAdmin: false,
};

const supportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {
    setSupportLoading: (
      state,
      action: PayloadAction<{ isLoading: boolean }>
    ) => {
      state.supportLoading = action.payload.isLoading;
    },
    resetChatState: (state) => {
      state.messages = [];
      state.offset = 0;
      state.hasMoreUp = true;
    },
    setIsAdmin: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setHasMoreUp: (state, action: PayloadAction<boolean>) => {
      state.hasMoreUp = action.payload;
    },
    addMessage: (state, action: PayloadAction<ServerMessage>) => {
      state.messages = [action.payload, ...state.messages];
    },
    setStreaming: (state, action: PayloadAction<boolean>) => {
      state.streaming = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(supportThunks.getAllRequestsAT.fulfilled, (state, action) => {
        state.requests = action.payload.requests;

        state.requestsArchive = action.payload.requests.filter((request) => {
          return request.status === "Вопрос решен";
        });
      })
      .addCase(
        supportThunks.getAllRequestsForAdminAT.fulfilled,
        (state, { payload }) => {
          state.requests = payload.requests;

          state.requestsArchive = payload.requests.filter((request) => {
            return request.status === "Вопрос решен";
          });
        }
      )
      .addCase(supportThunks.getCurrentRequestAT.fulfilled, (state, action) => {
        state.currentRequest = action.payload.request;
      })
      .addCase(supportThunks.loadChatMessagesAT.fulfilled, (state, action) => {
        state.loading = false;
        const { messages, direction } = action.payload;

        if (direction === "up") {
          state.messages = [...state.messages, ...messages];

          // Обновляем offset на количество полученных сообщений
          state.offset += messages.length;

          if (messages.length < state.limit) {
            state.hasMoreUp = false;
          }
        } else {
          state.messages = [...messages, ...state.messages];
        }
      })
      .addCase(supportThunks.loadChatMessagesAT.pending, (state) => {
        state.loading = true;
      })
      .addCase(supportThunks.loadChatMessagesAT.rejected, (state) => {
        state.loading = false;
      })

      .addCase(
        supportThunks.createNewMessageByUserAT.fulfilled,
        (state, action) => {
          state.messages = [action.payload.message, ...state.messages];
        }
      )
      .addCase(
        supportThunks.createNewMessageByAdmin.fulfilled,
        (state, action) => {
          state.messages = [action.payload.message, ...state.messages];
        }
      )
      .addCase(
        supportThunks.sendRequestInArchiveAT.fulfilled,
        (state, action) => {
          if (action.payload.id) {
            const requestToArchive = state.requests?.find(
              (request) => request.id === action.payload.id
            );

            if (requestToArchive) {
              const updatedRequest = {
                ...requestToArchive,
                status: "Вопрос решен",
              };

              state.requestsArchive = state.requestsArchive
                ? [...state.requestsArchive, updatedRequest]
                : [requestToArchive];

              if (state.requests) {
                state.requests = state.requests?.filter(
                  (request) => request.id !== action.payload.id
                );
              }
            }
          }
        }
      )
      .addCase(supportThunks.deleteRequestAT.fulfilled, (state, action) => {
        if (action.payload.id) {
          if (state.requests) {
            state.requests = state.requests?.filter(
              (request) => request.id !== action.payload.id
            );
          }
          if (state.requestsArchive) {
            state.requestsArchive = state.requestsArchive?.filter(
              (request) => request.id !== action.payload.id
            );
          }
        }
      });
  },
});

export const supportReducer = supportSlice.reducer;
export const supportActions = supportSlice.actions;
