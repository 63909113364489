import { createAsyncThunk } from "@reduxjs/toolkit";
import { backgroundReplacementActions } from "../../slice";
import bgReplacementApi from "../api/backgroundReplacementApi";

export const getAllReplacements = createAsyncThunk(
  "bgReplacement/getAllReplacements",
  async (arg: { offset: number; isMore?: boolean }, { dispatch }) => {
    if (arg.isMore) {
      dispatch(backgroundReplacementActions.setIsGettingMoreReplacements(true));
    } else {
      dispatch(backgroundReplacementActions.isLoading(true));
    }
    dispatch(backgroundReplacementActions.isError(null));
    try {
      const replacements = await bgReplacementApi.getAllReplacements(
        arg.offset
      );
      if (replacements.length === 0) {
        dispatch(backgroundReplacementActions.setHasMoreReplacements(false));
      }
      // Используйте Promise.all для получения всех урлов картинок
      const imgUrls = await Promise.all(
        replacements.map(async (replacement) =>
          bgReplacementApi.getReplImgAsBase64(replacement.id)
        )
      );

      // Объединяем данные
      const result = replacements.map((replacement, index) => ({
        ...replacement,
        ...imgUrls[index],
      }));
      dispatch(
        backgroundReplacementActions.setOffset(arg.offset + replacements.length)
      );
      return { replacements: result, isMore: arg.isMore };
    } catch (e) {
      dispatch(backgroundReplacementActions.isError(e));
    } finally {
      if (arg.isMore) {
        dispatch(
          backgroundReplacementActions.setIsGettingMoreReplacements(false)
        );
      } else {
        dispatch(backgroundReplacementActions.isLoading(false));
      }
    }
  }
);
