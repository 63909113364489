import { API_PREFIX } from "shared/api/constants/api";
import useHttp from "shared/api/services/api";
import { TUser } from "../../types/TUserSchema";

export interface TUpdatedUserData {
  // <Partial<TUser>>
  name?: string;
  password?: string;
  email?: string;
  is_active?: boolean;
  is_superuser?: boolean;
  is_verified?: boolean;
  phone_number?: string;
  surname?: string;
  patronymic?: string;
  country?: string;
  address?: string;
  postal_code?: string;
  applied_theme?: string;
  avatar_path?: string;
}
export class userApi {
  static http = useHttp(API_PREFIX.USERS);

  static async getMe(): Promise<TUser> {
    const { data } = await userApi.http.get(`/me`);
    return data;
  }
  static async getAdminUser(id: string) {
    const { data } = await userApi.http.get(`/${id}`);
    return data;
  }
  static async updateUser(updatedData: TUpdatedUserData) {
    const { data } = await userApi.http.patch(`/me`, updatedData);
    return data;
  }

  static async deleteUser(id: string) {
    const { data } = await userApi.http.delete(`/${id}`);
    return data;
  }
  static async getAvatar() {
    const { data } = await userApi.http.get(`/me/avatar`);
    return data;
  }
  static async updateAvatar(avatarImg: File) {
    const formData = new FormData();
    formData.append("avatar", avatarImg);

    const { data } = await userApi.http.post(`/me/avatar`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  }
}
