import { createAsyncThunk } from "@reduxjs/toolkit";
import { textDocumentsActions } from "../../slice";
import foldersApi from "../api/folderApi";

export const createNewFolder = createAsyncThunk(
  "textDocuments/createNewFolder",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(textDocumentsActions.isError(null));
      // dispatch(textDocumentsActions.isLoading(true));
      const data = await foldersApi.createFolder();

      return { folder: data };
    } catch (e: any) {
      dispatch(textDocumentsActions.isError(e));
      return rejectWithValue(e.message);
    } finally {
      // dispatch(textDocumentsActions.isLoading(false));
    }
  }
);
