const DarkTheme = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8329 9.9059C8.81132 9.9059 6.23924 7.39989 6.23924 3.39315C6.23924 2.56519 6.43995 1.38239 6.70757 0.768817C6.77446 0.59879 6.78933 0.495296 6.78933 0.421371C6.78933 0.221774 6.64066 0 6.35075 0C6.26898 0 6.09057 0.0221774 5.92703 0.081317C3.17656 1.1754 1.33301 4.11761 1.33301 7.21505C1.33301 11.5618 4.6633 14.6667 9.01945 14.6667C12.2234 14.6667 14.9961 12.7372 15.9179 10.3273C15.9848 10.1573 15.9997 9.97983 15.9997 9.91328C15.9997 9.63975 15.7692 9.45499 15.5611 9.45499C15.4644 9.45499 15.3827 9.47713 15.2414 9.52154C14.669 9.70631 13.7473 9.9059 12.8329 9.9059Z"
      fill="#101018"
    />
  </svg>
);

export default DarkTheme;
