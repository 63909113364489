import { createAsyncThunk } from "@reduxjs/toolkit";
import foldersApi from "../api/foldersApi";
import { chatBotActions } from "../../slice";

export const deleteFolderWithChats = createAsyncThunk(
  "chatBot/deleteFolderWithChats",
  async (
    { folder_id }: { folder_id: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(chatBotActions.isError(null));
      const res = await foldersApi.deleteFolder(folder_id);

      return { deletedFolderId: folder_id };
    } catch (e) {
      dispatch(chatBotActions.isError(e));
      rejectWithValue(e);
    }
  }
);
