import { createAsyncThunk } from "@reduxjs/toolkit";
import { RequestsType } from "../../types/supportDataTypes";
import SupportApi from "../../api/supportApi";
import { supportActions } from "../index";

export const getAllRequestsForAdminAT = createAsyncThunk<
  { requests: RequestsType[] },
  void
>("support/getAllRequestsForAdmin", async (_, thunkApi) => {
  const { dispatch, rejectWithValue } = thunkApi;
  dispatch(supportActions.setSupportLoading({ isLoading: true }));
  try {
    const res = await SupportApi.getAllRequestsForAdmin();
    return { requests: res };
  } catch (e) {
    return rejectWithValue(e);
  } finally {
    dispatch(supportActions.setSupportLoading({ isLoading: false }));
  }
});
