import React from "react";
import styles from "./SendMessage.module.scss";
import { IcAirplane } from "../../../../../../../shared/assets/icons/IcAirplane";

interface SendMessageProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSend: () => void;
  status: string | null;
}

export const SendMessage: React.FC<SendMessageProps> = ({
  value,
  onChange,
  onSend,
  status,
}) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      onSend();
    }
  };

  return (
    <div className={styles.sendMessageContainer}>
      <textarea
        className={styles.input}
        placeholder={
          status === "Вопрос решен" ? "Чат закрыт" : "Отправить сообщение"
        }
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        rows={3}
        disabled={status === "Вопрос решен"}
      ></textarea>
      <button
        disabled={status === "Вопрос решен"}
        className={styles.sendButton}
        onClick={onSend}
      >
        <IcAirplane />
      </button>
    </div>
  );
};
