import { API_PREFIX } from "shared/api/constants/api";
import useHttp from "shared/api/services/api";
import { TFolder } from "../../slice/types";

export default class foldersApi {
  static http = useHttp(API_PREFIX.CHAT_BOT_FOLDER);

  static async getFoldersList() {
    const { data } = await foldersApi.http.get("");
    return data;
  }
  static async getFolder(folder_id: string) {
    const { data } = await foldersApi.http.get(`/${folder_id}`);
    return data;
  }
  static async createFolder(): Promise<TFolder> {
    const { data } = await foldersApi.http.post("", {
      name: "Новая папка",
    });
    return data;
  }

  static async addChatToFolder({
    folder_id,
    chat_id,
  }: {
    folder_id: string;
    chat_id: string;
  }) {
    const { data } = await foldersApi.http.post(
      `/${folder_id}/add_chat/${chat_id}`
    );
    return data;
  }

  static async deleteChatFromFolder({
    chat_id,
    folder_id,
  }: {
    chat_id: string;
    folder_id: string;
  }) {
    const { data } = await foldersApi.http.post(
      `/${folder_id}/remove_chat/${chat_id}`
    );
    return data;
  }
  static async updateFolder(folder_id: string, folderName: string) {
    const { data } = await foldersApi.http.patch(`/${folder_id}`, {
      name: folderName,
    });
    return data;
  }
  static async deleteFolder(folder_id: string) {
    const { data } = await foldersApi.http.delete(`/${folder_id}`);
    return data;
  }
}
