import axios from "axios";
import { API_PREFIX, API_URL, VERSION } from "../constants/api";
import { refreshToken } from "./refresh";
import { LS_ACCESS_TOKEN } from "../constants/localStorage";
import { RoutePath } from "shared/config/routeConfig/routeConfig";

type TApiPrefix = (typeof API_PREFIX)[keyof typeof API_PREFIX];
const useHttp = (prefix: TApiPrefix) => {
  const $api = axios.create({
    baseURL: `${API_URL}/${VERSION}/${prefix}`,
    withCredentials: true,
  });

  // Перехватываем запросы для добавления токена авторизации
  $api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(LS_ACCESS_TOKEN);
      // console.log(token);
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Перехватываем ответы для обработки ошибок
  $api.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      // debugger
      const originalConfig = err.config;
      if (originalConfig.url !== RoutePath.LOGIN && err.response) {
        if (err.response.status === 401 && !originalConfig._retry) {
          // console.log("refresh");
          // refresh expired
          originalConfig._retry = true; // Отмечаем, что повторный запрос будет выполнен

          const accessToken = await refreshToken();

          originalConfig.headers["Authorization"] = `Bearer ${accessToken}`;

          return $api(originalConfig);
        }
      }

      // Возвращаем отклоненное обещание с ошибкой
      // window.location.href = '/';
      return Promise.reject(err);
    }
  );
  return $api;
};

export default useHttp;
