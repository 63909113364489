import audiosApi from "../api/audiosApi";
import { audiosActions } from "./../../slice/index";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const retryCurrentAudio = createAsyncThunk(
  "audios/retryCurrentAudio",
  async (arg: { id: string; voice: Blob }, { rejectWithValue, dispatch }) => {
    dispatch(audiosActions.isLoading(true));
    dispatch(audiosActions.isError(null));

    try {
      const res = await audiosApi.retryVoiceToText(arg.id, arg.voice);
      return { audio: res.audio, tokens_usage: res.tokens_usage };
    } catch (e) {
      dispatch(audiosActions.isError(e));
      rejectWithValue(e);
    } finally {
      dispatch(audiosActions.isLoading(false));
    }
  }
);
