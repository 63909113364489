import { API_PREFIX } from "shared/api/constants/api";
import useHttp from "shared/api/services/api";

export default class foldersApi {
  static http = useHttp(API_PREFIX.DOCUMENTS_FOLDERS);

  static async getFoldersList() {
    const { data } = await foldersApi.http.get("");
    return data;
  }
  static async getFolder(folder_id: string) {
    const { data } = await foldersApi.http.get(`/${folder_id}`);
    return data;
  }
  static async createFolder() {
    const { data } = await foldersApi.http.post("", {
      name: "Новая папка",
    });
    return data;
  }
  static async updateFolder(folder_id: string, folderName: string) {
    const { data } = await foldersApi.http.patch(`/${folder_id}`, {
      name: folderName,
    });
    return data;
  }
  static async deleteFolder(folder_id: string) {
    const { data } = await foldersApi.http.delete(`/${folder_id}`);
    return data;
  }
  static async addDocumentToFolder({
    doc_id,
    folder_id,
  }: {
    doc_id: string;
    folder_id: string;
  }) {
    const { data } = await foldersApi.http.post(
      `/${folder_id}/add_document/${doc_id}`
    );
    return data;
  }
  static async deleteDocumentFromFolder({
    doc_id,
    folder_id,
  }: {
    doc_id: string;
    folder_id: string;
  }) {
    const { data } = await foldersApi.http.post(
      `/${folder_id}/remove_document/${doc_id}`
    );
    return data;
  }
}
