import { createAsyncThunk } from "@reduxjs/toolkit";
import { RequestsType } from "../../types/supportDataTypes";
import SupportApi from "../../api/supportApi";

export const getCurrentRequestAT = createAsyncThunk<
  { request: RequestsType },
  {
    id: string;
  }
>("support/getCurrentRequest", async (arg, thunkApi) => {
  const { rejectWithValue } = thunkApi;

  try {
    const res = await SupportApi.getCurrentRequest({ chatId: arg.id });
    return { request: res };
  } catch (e) {
    return rejectWithValue(e);
  }
});
