import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatBotActions } from "../../slice";
import { chatBotApi } from "../api/chatBotApi";

export const createNewChat = createAsyncThunk(
  "chatBot/createNewChat",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(chatBotActions.isError(null));
      const chat = await chatBotApi.createChat();

      return { chat };
    } catch (e: any) {
      dispatch(chatBotActions.isError(e));
      return rejectWithValue(e.message);
    }
  }
);
