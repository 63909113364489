import { AppRouter } from "./providers/router";
import { useTheme } from "./providers/ThemeProvider";
import "./styles/index.css";
function App() {
  const { theme } = useTheme();
  return (
    <div className={`app ${theme}`}>
      <AppRouter />
    </div>
  );
}

export default App;
