import React from "react";
import MessagesWrapper from "./MessagesWrapper/MessagesWrapper";
import { ServerMessage } from "pages/content/Support/model/types/supportDataTypes";

interface MessagesListProps {
  messages: ServerMessage[] | null;
}

const MessagesList: React.FC<MessagesListProps> = ({ messages }) => {
  return (
    <>
      {messages?.map((message) => (
        <MessagesWrapper message={message} key={message.id} />
      ))}
    </>
  );
};

export default MessagesList;
