import { getAllRequestsForAdminAT } from "./getAllRequestsForAdmin";
import { getAllRequestsAT } from "./getAllRequestsAT";
import { createNewRequestAT } from "./createNewRequestAT";
import { getChatsMessagesUser } from "./getChatsMessagesUser";
import { getCurrentRequestAT } from "./getCurrentRequestAT";
import { createNewMessageByUserAT } from "./createNewMessageByUser";
import { createNewMessageByAdmin } from "./createNewMessageByAdmin";
import { getChatsMessagesAdminAT } from "./getChatsMessagesAdminAT";
import { sendRequestInArchiveAT } from "./sendRequestInArchiveAT";
import { loadChatMessagesAT } from "./loadChatMessagesAT";
import { deleteRequestAT } from "./deleteRequestAT";

export const supportThunks = {
  getAllRequestsAT,
  getAllRequestsForAdminAT,
  createNewRequestAT,
  getChatsMessagesUser,
  getCurrentRequestAT,
  createNewMessageByUserAT,
  createNewMessageByAdmin,
  getChatsMessagesAdminAT,
  sendRequestInArchiveAT,
  loadChatMessagesAT,
  deleteRequestAT,
};
