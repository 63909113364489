export enum AppRoutes {
  MAIN = "MAIN",
  DOCUMENTS = "DOCUMENTS",
  TEXT_EDITOR = "TEXT_EDITOR",
  TEXT_EDITOR_DOCUMENT = "TEXT_EDITOR_DOCUMENT",
  CHAT_BOT = "CHAT_BOT",
  CHAT_BOT_CHAT = "CHAT_BOT_CHAT",
  AI_IMAGES = "AI_IMAGES",
  AI_VIDEOS = "AI_VIDEOS",
  FILE_ANALYZER = "FILE_ANALYZER",
  CODE_GENERATION = "CODE_GENERATION",
  PHOTO_ANIMATION = "PHOTO_ANIMATION",
  ARTICLES_CONSTRUCTOR = "ARTICLES_CONSTRUCTOR",
  LOGO_GENERATION = "LOGO_GENERATION",
  AI_EXPERTS = "AI_EXPERTS",
  AUDIO_TRANSCRIPT = "AUDIO_TRANSCRIPT",
  VOICE_OVER = "VOICE_OVER",
  SUPPORT = "SUPPORT",
  SUPPORT_NEW_REQUEST = "NEW_REQUEST",
  AI_TEMPLATES = "AI_TEMPLATES",
  CHAT_GPT = "CHAT_GPT",
  PAYMENT = "PAYMENT",
  TARIFFS = "TARIFFS",

  SETTINGS = "SETTINGS",

  LOGIN = "LOGIN",
  TG_LINK="TG_LINK",
  REGISTER = "REGISTER",
  RESET_PASSWORD = "RESET_PASSWORD",
  CREATE_NEW_PASSWORD = "CREATE_NEW_PASSWORD",
  RECOVERY_MESSAGE = "RECOVERY_MESSAGE",
  ERROR_MESSAGE = "ERROR_MESSAGE",
  EMAIL_CONFIRMATION = "EMAIL_CONFIRMATION",
  SET_ACCOUNT_PASSWORD = "SET_ACCOUNT_PASSWORD",
  PASSWORD_SUCCESS_CHANGED = "PASSWORD_SUCCESS_CHANGED",

  NOT_FOUND = "NOT_FOUND",
  BACKGROUND_REPLACEMENT = "BACKGROUND_REPLACEMENT",
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: "/dashboard/user",
  [AppRoutes.DOCUMENTS]: "/dashboard/user/openai/documents/all",
  [AppRoutes.TEXT_EDITOR]: "/dashboard/user/editor",
  [AppRoutes.TEXT_EDITOR_DOCUMENT]: "/dashboard/user/editor/:id",
  [AppRoutes.CHAT_BOT]: "/dashboard/user/openai/generator/ai_vision/workbook",
  [AppRoutes.CHAT_BOT_CHAT]:
    "/dashboard/user/openai/generator/ai_vision/workbook/:id",
  [AppRoutes.LOGO_GENERATION]: "/dashboard/user/openai/generator/logo",
  [AppRoutes.AI_IMAGES]: "/dashboard/user/openai/generator/ai_image_generator",
  [AppRoutes.AI_VIDEOS]: "/dashboard/user/openai/generator/ai_video",
  [AppRoutes.FILE_ANALYZER]: "/dashboard/user/openai/generator/ai_pdf/workbook",
  [AppRoutes.CODE_GENERATION]:
    "/dashboard/user/openai/generator/ai_code_generator",
  [AppRoutes.PHOTO_ANIMATION]:
    "/dashboard/user/openai/generator/ai_photo_animation",
  [AppRoutes.ARTICLES_CONSTRUCTOR]: "/dashboard/user/openai/articlewizard/new",
  [AppRoutes.AI_EXPERTS]: "/dashboard/user/openai/experts",

  [AppRoutes.AUDIO_TRANSCRIPT]:
    "/dashboard/user/openai/generator/ai_speech_to_text",
  [AppRoutes.VOICE_OVER]: "/dashboard/user/openai/generator/ai_voiceover",
  [AppRoutes.SUPPORT]: "/dashboard/support/my-requests",
  [AppRoutes.SUPPORT_NEW_REQUEST]: "/dashboard/support/new-request",
  [AppRoutes.AI_TEMPLATES]: "/dashboard/templates",
  [AppRoutes.CHAT_GPT]: "/dashboard/chat-gpt",
  [AppRoutes.PAYMENT]: "/dashboard/user/payment",
  [AppRoutes.TARIFFS]: "/dashboard/user/tariffs",

  [AppRoutes.BACKGROUND_REPLACEMENT]: "/dashboard/background-replacement",
  [AppRoutes.SETTINGS]: "/dashboard/user/settings",

  [AppRoutes.LOGIN]: "/login",
  [AppRoutes.TG_LINK]: "/tg-link",
  [AppRoutes.REGISTER]: "/register",
  [AppRoutes.CREATE_NEW_PASSWORD]: "/create-new-password",
  [AppRoutes.RESET_PASSWORD]: "/reset-password",
  [AppRoutes.RECOVERY_MESSAGE]: "/recovery-message",
  [AppRoutes.ERROR_MESSAGE]: "/error-message",
  [AppRoutes.EMAIL_CONFIRMATION]: "/email-confirmation",
  [AppRoutes.SET_ACCOUNT_PASSWORD]: "/set-account-password",
  [AppRoutes.PASSWORD_SUCCESS_CHANGED]: "/password-success-changed",

  [AppRoutes.NOT_FOUND]: "*",
};

export enum ROUTE_LAYOUTS {
  AUTH = "AUTH",
  CONTENT = "CONTENT",
  DOCUMENTS = "DOCUMENTS",
  CHAT_BOT = "CHAT_BOT",

  STAND_ALONE = "STAND_ALONE",
}
