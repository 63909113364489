import { createContext } from "react";

export enum TTheme {
  LIGHT = "app_light_theme",
  DARK = "app_dark_theme",
}

export interface TThemeContextProps {
  theme: TTheme;
  setTheme: (theme: TTheme) => void;
}

export const ThemeContext = createContext<TThemeContextProps>({
  theme: TTheme.LIGHT,
  setTheme: () => {},
});

export const LOCAL_STORAGE_THEME_KEY = "theme";
