import useHttp from "shared/api/services/api";
import { API_PREFIX } from "shared/api/constants/api";
import { TChat, TChatBotSchema, TMessage } from "../../slice/types";
import { FamilyModelNames, TGptFamilyModels } from "shared/api/types/types";

export type TUpdateChatData = {
  name?: string;
  model?: FamilyModelNames;
};

export class chatBotApi {
  static http = useHttp(API_PREFIX.CHAT_BOT);

  static async getGptModels(): Promise<TGptFamilyModels> {
    const { data } = await chatBotApi.http.get("/models");
    return data;
  }
  static async getAllChats(): Promise<TChatBotSchema["allChats"]> {
    const { data } = await chatBotApi.http.get("");
    return data;
  }
  static async createChat(): Promise<TChat> {
    const { data } = await chatBotApi.http.post(``, {
      name: "Новый чат",
    });
    return data;
  }

  static async getChat(id: TChat["id"]): Promise<TChat> {
    const { data } = await chatBotApi.http.get(`/${id}`);
    return data;
  }

  static async getMessagesStory(
    id: TChat["id"],
    offset: number
  ): Promise<{ messages_story: TMessage[] }> {
    const limit = 100;
    const { data } = await chatBotApi.http.get(
      `/messages_story/${id}?offset=${offset}&limit=${limit}`
    );
    return data;
  }
  static async getChatFile(id: TChat["id"], path: string) {
    const { data } = await chatBotApi.http.get(`/${id}/files/${path}`);
    return data;
  }

  static async deleteChat(chat_id: TChat["id"]) {
    const { data } = await chatBotApi.http.delete(`/${chat_id}`);
    return data;
  }
  static async deleteFile(chat_id: TChat["id"], path: string) {
    const { data } = await chatBotApi.http.delete(`/${chat_id}/files/${path}`);
    return data;
  }
  static async updateChat(
    chat_id: string,
    newData: TUpdateChatData
  ): Promise<TChat> {
    const { data } = await chatBotApi.http.patch(`/${chat_id}`, newData);
    return data;
  }
  static async sendMessage(
    chat_id: string,
    data: {
      content: string;
      image?: string;
    },
    isRetry = false
  ) {
    if (!data.image) {
      data.image = "";
    }
    const { data: message } = await chatBotApi.http.post(
      isRetry ? `/retry_send_message/${chat_id}` : `/send_message/${chat_id}`,
      {
        ...data,
        role: "user",
      }
    );
    return message;
  }

  static async sendGenerationFiles(
    chatId: string,
    requestData: {
      files: File[];
      message: string;
    },
    isRetry = false
  ) {
    const formData = new FormData();
    formData.append("message", requestData.message);
    requestData.files.forEach((file) => {
      formData.append("files", file);
    });
    const { data } = await chatBotApi.http.post(
      isRetry ? `/retry_files/${chatId}` : `/files/${chatId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  }
}
