import { getMe } from "./getMe";
import { getUserAvatar } from "./getUserAvatar";
import { updateUserData } from "./updatedUserData";
import { updateUserAvatar } from "./updateUserAvatar";
export const userThunks = {
  getMe,
  updateUserData,
  getUserAvatar,
  updateUserAvatar,
};
