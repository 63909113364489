import { createAsyncThunk } from "@reduxjs/toolkit";
import textDocumentsApi from "../api/documentsApi";
import { textDocumentsActions } from "../../slice";

export const createNewVersion = createAsyncThunk(
  "textDocuments/createNewVersion",
  async (arg: { doc_id: string; data: any }, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      dispatch(textDocumentsActions.isError(null));
      const res = await textDocumentsApi.createNewVersion(arg);
      return { version: res, newBlocks: arg.data };
    } catch (e: any) {
      dispatch(textDocumentsActions.isError(e));
      return rejectWithValue(e.message);
    }
  }
);
