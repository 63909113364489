export const IcBasket = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect
        x="2"
        y="2.66663"
        width="12"
        height="2.66667"
        rx="1.33333"
        stroke="#101018"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33325 5.33337V12C3.33325 12.7364 3.93021 13.3334 4.66659 13.3334H11.3333C12.0696 13.3334 12.6666 12.7364 12.6666 12V5.33337"
        stroke="#101018"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66675 7.99996H9.33341"
        stroke="#101018"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
