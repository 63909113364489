import { createAsyncThunk } from "@reduxjs/toolkit";
import { backgroundReplacementActions } from "../../slice";
import bgReplacementApi from "../api/backgroundReplacementApi";
import { TCreateBackgroundData } from "../../types/api";
import { userSubPlanActions } from "entities/UserSubPlan";

export const createBackground = createAsyncThunk(
  "bgReplacement/createBackground",
  async (arg: { data: TCreateBackgroundData }, { dispatch }) => {
    dispatch(backgroundReplacementActions.isError(null));
    dispatch(backgroundReplacementActions.setIsGenerating(true));

    try {
      const newImg = await bgReplacementApi.createBackground(arg.data);
      if (newImg.tokens_usage) {
        dispatch(
          userSubPlanActions.changeTokensCount({
            used_tokens: newImg.tokens_usage,
          })
        );
      }
      return {
        newImg: {
          ...arg.data,
          size: arg.data.width + "x" + arg.data.height,
          ...newImg,
        },
      };
    } catch (e) {
      dispatch(backgroundReplacementActions.isError(e));
    }
  }
);
