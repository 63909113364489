import { createAsyncThunk } from "@reduxjs/toolkit";
import foldersApi from "../api/folderApi";
import { textDocumentsActions } from "../../slice";

export const addDocumentToFolder = createAsyncThunk(
  "textDocuments/addDocumentToFolder",
  async (arg: { entity_id: string; folder_id: string }, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    try {
      dispatch(textDocumentsActions.isError(null));
      foldersApi.addDocumentToFolder({
        doc_id: arg.entity_id,
        folder_id: arg.folder_id,
      });

      return { documentId: arg.entity_id, folder_id: arg.folder_id };
    } catch (e: any) {
      dispatch(textDocumentsActions.isError(e));
      return rejectWithValue(e.response.data);
    }
  }
);
