import { createAsyncThunk } from "@reduxjs/toolkit";

import SupportApi from "../../api/supportApi";
import { ServerMessage } from "../../types/supportDataTypes";

export const getChatsMessagesAdminAT = createAsyncThunk<
  { adminChat: ServerMessage[] },
  { chatId: string; offset: number; limit: number }
>("support/getChatsMessagesAdmin", async (arg, thunkApi) => {
  const { dispatch, rejectWithValue } = thunkApi;

  try {
    const res = await SupportApi.getChatsMessagesAdmin({
      chatId: arg.chatId,
      offset: arg.offset,
      limit: arg.limit,
    });

    return { adminChat: res };
  } catch (e) {
    return rejectWithValue(e);
  }
});
