import { createAsyncThunk } from "@reduxjs/toolkit";
import documentsApi from "../api/documentsApi";
import { TDocument } from "../../slice/types";
import { textDocumentsActions } from "../../slice";

export const deleteDocument = createAsyncThunk(
  "textDocuments/deleteDocument",
  async ({ entity_id: doc_id }: { entity_id: string }, thunkAPI) => {
    const { rejectWithValue, getState, dispatch } = thunkAPI;
    // const { id } = getState().user.currentOrganization;

    try {
      dispatch(textDocumentsActions.isError(null));

      // const worker_id = await getWorkerMe({ organization_id: id });
      const res: TDocument = await documentsApi.deleteDocument(doc_id);

      return { deletedDoc: res, doc_id };
    } catch (e) {
      dispatch(textDocumentsActions.isError(e));

      rejectWithValue(e);
    }
  }
);
