import { createSlice } from "@reduxjs/toolkit";
import { TAudiosSchema } from "./types";
import { audiosThunks } from "../services/thunks";

const initialState: TAudiosSchema = {
  allAudios: [],
  currentAudio: null,

  isLoading: false,
  isError: false,
  isTranscripting: false,
  searchedAudios: null,
};

const audiosSlice = createSlice({
  name: "audios",
  initialState,

  reducers: {
    isLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    isError: (state, { payload }) => {
      state.isError = payload;
    },
    resetSearchedAudios: (state) => {
      state.searchedAudios = null;
    },
    setIsTranscripting: (state, { payload }) => {
      state.isTranscripting = payload;
    },
    setCurrentAudio: (state, { payload }) => {
      if (
        payload.id === "transcripting" &&
        !state.allAudios.find((audio) => audio.id === "transcripting")
      ) {
        state.allAudios = [
          {
            id: payload.id,
            transcription: "",
            path: "",
            created_at: "",
          },
          ...state.allAudios,
        ];
        state.currentAudio = state.allAudios[0];
      } else {
        const currentAudio = state.allAudios.find(
          (audio) => audio.id === payload.id
        );
        if (currentAudio) {
          state.currentAudio = currentAudio;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(audiosThunks.getAllAudios.fulfilled, (state, { payload }) => {
        // state.isLoading = false;
        state.allAudios = payload?.audios;
      })
      // .addCase(audiosThunks.getCurrentAudio.fulfilled, (state, { payload }) => {
      //   state.currentAudio = payload?.audio;
      // })
      .addCase(audiosThunks.deleteAudio.fulfilled, (state, { payload }) => {
        if (payload) {
          state.allAudios = state.allAudios.filter(
            (audio) => audio.id !== payload.id
          );
          if (state.currentAudio?.id === payload.id) {
            if (state.allAudios.length > 0) {
              state.currentAudio = state.allAudios[0];
            } else {
              state.currentAudio = null;
            }
          }
        }
      })
      .addCase(
        audiosThunks.retryCurrentAudio.fulfilled,
        (state, { payload }) => {
          state.currentAudio = payload?.audio;
        }
      )
      .addCase(
        audiosThunks.getAudioTranscript.fulfilled,
        (state, { payload }) => {
          //uploadAudio
          if (payload) {
            state.currentAudio = payload?.audio;
            state.allAudios[0] = payload?.audio;
            state.isTranscripting = false;
          }
        }
      )
      .addCase(
        audiosThunks.getSearchedTranscript.fulfilled,
        (state, { payload }) => {
          state.searchedAudios = payload?.audios;
        }
      );
  },
});

export const audiosActions = audiosSlice.actions;

export const audiosReducer = audiosSlice.reducer;
