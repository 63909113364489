import { createSlice } from "@reduxjs/toolkit";
import { tariffsThunks } from "../services/thunks";
import { TTariff, TTariffsSchema } from "./types";
import { PLANS, PLANS_DISCOUNTS } from "../../constants/plans";

const initialState: TTariffsSchema = {
  subscriptions: null,
  plans: null,
  isLoading: false,
  isError: false,
  isAnnualPlan: false,
};

const tariffsSlice = createSlice({
  name: "tariffs",
  initialState,

  reducers: {
    isLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    isError: (state, { payload }) => {
      state.isError = payload;
    },
    setIsAnnualPlan: (state, { payload }) => {
      state.isAnnualPlan = payload;

      if (state.plans) {
        const key = payload ? "annual" : "monthly";
        state.subscriptions = state.plans[key].map((plan: TTariff) => ({
          ...plan,
          ...PLANS[plan.title as keyof typeof PLANS],
          discount:
            // @ts-ignore
            PLANS_DISCOUNTS[key][plan.title as keyof typeof PLANS_DISCOUNTS],
        }));
      }

      // if (payload && state.plans?.annual) {
      //   // // @ts-ignore
      //   // state.subscriptions = MONTHLY_PLANS.map((plan, i) => {
      //   //   return i === 0
      //   //     ? plan
      //   //     : {
      //   //         // @ts-ignore
      //   //         ...state.plans.annual[i],
      //   //         ...plan,
      //   //       };
      //   // });
      //   state.subscriptions = state.plans.annual.map((plan: TTariff) => ({
      //     ...plan,
      //     ...PLANS[plan.title as keyof typeof PLANS],
      //   }));
      // } else {
      //   // state.subscriptions = MONTHLY_PLANS.map((plan, i) => ({
      //   //   // @ts-ignore
      //   //   ...state.plans.monthly[i],
      //   //   ...plan,
      //   // }));
      //   // @ts-ignore
      //   state.subscriptions = state.plans.monthly.map((plan: TTariff) => ({
      //     ...plan,
      //     ...PLANS[plan.title as keyof typeof PLANS],
      //   }));
      // }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      tariffsThunks.getSubPlansList.fulfilled,
      (state, { payload }) => {
        if (payload) {
          // state.subscriptions = payload.plans;
          state.subscriptions = payload.subscriptions;
          state.plans = payload.plans;
        }
      }
    );
  },
});

export const tariffsActions = tariffsSlice.actions;
export const tariffsReducer = tariffsSlice.reducer;
