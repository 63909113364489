import { API_PREFIX } from "shared/api/constants/api";
import useHttp from "shared/api/services/api";
import { TCreateBackgroundData } from "../../types/api";
import { TReplacement } from "../../types/TBackgroundReplacementSchema";

export default class bgReplacementApi {
  static http = useHttp(API_PREFIX.BG_REPLACEMENT);

  static async removeBackground(image: File) {
    const formData = new FormData();
    formData.append("image", image);

    const { data } = await bgReplacementApi.http.post(
      "/remove_background",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  }
  static async getAllReplacements(
    offset: number = 0,
    limit: number = 10
  ): Promise<TReplacement[]> {
    const { data } = await bgReplacementApi.http.get(
      `all?offset=${offset}&limit=${limit}`
    );
    return data;
  }
  static async getReplImgAsFile(repl_id: string) {
    const { data } = await bgReplacementApi.http.get(`/${repl_id}/as_file`);
    return data;
  }
  static async getReplImgAsBase64(repl_id: string) {
    const { data } = await bgReplacementApi.http.get(`/${repl_id}/as_base64`);
    return data;
  }
  static async createBackground(body: TCreateBackgroundData) {
    const { data } = await bgReplacementApi.http.post(`/create_background`, {
      ...body,
      autoresize: true,
    });
    return data;
  }
}
