const Lightning = (props) => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.58644 0.273807L6.69329 6.24668H11.4254C11.5494 6.24668 11.6519 6.34924 11.6519 6.47318C11.6519 6.52874 11.632 6.58003 11.5978 6.61849L4.8201 15.8919C4.74604 15.9931 4.60501 16.0144 4.50385 15.9403C4.43404 15.8905 4.40273 15.8064 4.4141 15.7267L5.30726 9.75237H0.575133C0.449789 9.75237 0.348633 9.65121 0.348633 9.52731C0.348633 9.47174 0.36857 9.42046 0.401351 9.38059L7.18048 0.107119C7.25454 0.00740028 7.39557 -0.0139747 7.49673 0.0586815C7.56651 0.109994 7.59785 0.194025 7.58644 0.273807Z"
      fill="white"
    />
  </svg>
);

export default Lightning;
