const Bright = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00002 10C6.2475 10 6.3992 9.83985 6.43913 9.59962C6.87028 6.97297 6.86227 6.94095 9.57687 6.44443C9.84033 6.39642 10 6.24426 10 5.996C10 5.75576 9.84033 5.5956 9.60082 5.55556C6.84632 4.995 6.88622 4.97097 6.43913 2.40841C6.3992 2.16016 6.2475 2 6.00002 2C5.7525 2 5.59283 2.16016 5.55289 2.4004C5.0978 5.03504 5.1457 5.08309 2.40719 5.55556C2.16768 5.58759 2 5.75576 2 5.996C2 6.24426 2.15967 6.39642 2.39922 6.44443C5.1457 7.005 5.11378 7.01303 5.55289 9.60762C5.59283 9.83985 5.7525 10 6.00002 10Z"
      fill="white"
    />
    <path
      d="M14.9956 22C15.4246 22 15.7311 21.7016 15.7836 21.2539C16.344 16.6633 16.5191 16.5053 21.2208 15.7856C21.7111 15.7153 22 15.4345 22 15.0044C22 14.5831 21.7111 14.2934 21.3083 14.2144C16.5278 13.2489 16.344 13.3191 15.7836 8.74606C15.7311 8.29841 15.4246 8 14.9956 8C14.5754 8 14.2689 8.2984 14.2076 8.72853C13.621 13.4245 13.4809 13.5561 8.6917 14.2144C8.2977 14.2671 8 14.5831 8 15.0044C8 15.4169 8.28892 15.7066 8.68291 15.7856C13.5072 16.7599 13.6122 16.7072 14.2076 21.289C14.2689 21.7016 14.5928 22 14.9956 22Z"
      fill="white"
    />
  </svg>
);

export default Bright;
