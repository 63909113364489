const IcGradientStack = () => (
  <svg
    width="36"
    height="28"
    viewBox="0 0 36 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 18L18 26L34 18M18 2L2 10L18 18L34 10L18 2Z"
      stroke="url(#paint0_linear_6097_134470)"
      strokeOpacity="0.72"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6097_134470"
        x1="2"
        y1="2"
        x2="34"
        y2="26"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0084FF" />
        <stop offset="0.82" stopColor="#C974FE" />
        <stop offset="1" stopColor="#C974FE" />
      </linearGradient>
    </defs>
  </svg>
);

export default IcGradientStack;
